import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {BlockContentDto} from "../models/dto/block-content-dto.interface";
import {UserAreaDto} from "../models/dto/user-area-dto.interface";
import databaseService from "../indexedDB.service";

export class UserAreaRepository extends BaseRepository<UserAreaDto> {
    constructor() {
        super(IndexedDBStores.USER_AREA);
    }

    async hasAreaIdForUserId(areaId: number): Promise<boolean> {
        const userAreaDto: UserAreaDto | undefined = await this.getEntityByAreaId(areaId);
        return !!userAreaDto;
    }

    private async getEntityByAreaId(areaId: number): Promise<UserAreaDto | undefined> {
        const res = await databaseService.getByKeyPath<UserAreaDto>(this.storeName, areaId);
        return res?.data
    }
}
