import {UrgentHelpDto} from "../models/dto/urgent-help-dto.interface";
import {UrgentHelpService} from "../services/urgent-help.service";

export class UrgentHelpController {
    private urgentHelpService: UrgentHelpService;

    constructor() {
        this.urgentHelpService = new UrgentHelpService;
    }

    async getUrgentHelp(): Promise<UrgentHelpDto> {
        try {
            return await this.urgentHelpService.getUrgentHelpById(1)
        } catch (error: any) {
            console.error("Failed to get urgent help data:", error.message);
            throw error
        }
    }
}
