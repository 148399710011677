import {IDBPDatabase, openDB} from 'idb';
import NotificationService from '../../common/services/notification.service';
import {DBConfig} from "./models/db-config.interface";
import {CustomDBRequestConfig} from "./models/custom-db-request-config.interface";
import {IndexeddbData} from "./models/indexeddb-data.interface";
import {migrations} from "./migrations/migration";
import {DateHelper} from "../utils/dateHelper";

export class DatabaseService {
    private readonly dbPromise: Promise<IDBPDatabase>;

    constructor(config: DBConfig) {
        const databaseVersion = Math.max(...Object.keys(migrations).map(Number)) || 1;
        this.dbPromise = openDB(config.dbName, databaseVersion, {
            upgrade(db, oldVersion, newVersion, transaction) {
                console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);
                if (newVersion === null) {
                    throw new Error('newVersion is null. Cannot perform migrations.');
                }
                for (let version = oldVersion + 1; version <= newVersion; version++) {
                    // @ts-ignore
                    const migration = migrations[version];
                    if (migration) {
                        migration(db, transaction);
                    }
                }
            },
        });
    }

    async getDB(): Promise<IDBPDatabase> {
        return this.dbPromise;
    }

    private async handleRequest<T>(
        operation: () => Promise<T>,
        config?: CustomDBRequestConfig
    ): Promise<T> {
        try {
            const result = await operation();

            if (config?.successMessage) {
                NotificationService.success(config.successMessage);
            }
            return result;
        } catch (error) {
            const customMessage: string = config?.errorMessage || 'An unexpected error occurred';
            NotificationService.error(customMessage, error);
            throw error;
        }
    }

    async add<T>(storeName: string, value: T, config?: CustomDBRequestConfig): Promise<IDBValidKey> {
        return this.handleRequest(async () => {
            const db = await this.dbPromise;

            const keyPath = db.objectStoreNames.contains(storeName)
                ? (db.transaction(storeName, 'readonly').objectStore(storeName).keyPath as string)
                : undefined;

            if (!keyPath) {
                throw new Error(`Key path not defined for store: ${storeName}`);
            }

            const keyValue = (value as any)[keyPath];
            if (keyValue !== undefined && (await db.get(storeName, keyValue)) !== undefined) {
                console.log(`Record with key "${keyValue}" already exists in store "${storeName}". Skipping add.`);
                return keyValue;
            }

            // const formattedValue = this.formatDates(value);

            return db.add(storeName, value);
        }, config);
    }

    async getAll<T>(storeName: string, config?: CustomDBRequestConfig): Promise<IndexeddbData<T[]>> {
        return this.handleRequest(async () => {
            const db = await this.dbPromise;

            const rawData: T[] = await db.getAll(storeName);

            return {
                store: storeName,
                data: rawData,
            };
        }, config);
    }

    async getByKeyPath<T>(storeName: string, key: number | string, config?: CustomDBRequestConfig): Promise<IndexeddbData<T | undefined>> {
        return this.handleRequest(async () => {
            const db = await this.dbPromise;

            const rawData: T = await db.get(storeName, key);

            return {
                store: storeName,
                data: rawData,
            };
        }, config);
    }

    async update<T>(storeName: string, value: T, config?: CustomDBRequestConfig): Promise<void> {
        await this.handleRequest(async () => {
            const db = await this.dbPromise;
            await db.put(storeName, value);
        }, config);
    }

    async delete(storeName: string, id: number | string, config?: CustomDBRequestConfig): Promise<void> {
        await this.handleRequest(async () => {
            const db = await this.dbPromise;
            await db.delete(storeName, id);
        }, config);
    }

    async clear(storeName: string, config?: CustomDBRequestConfig): Promise<void> {
        await this.handleRequest(async () => {
            const db = await this.dbPromise;
            await db.clear(storeName);
        }, config);
    }

    async getKeyPathForStore(storeName: string): Promise<string | null> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('psycho-app');

            request.onsuccess = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;

                if (!db.objectStoreNames.contains(storeName)) {
                    console.error(`Object store "${storeName}" does not exist in the database.`);
                    resolve(null);
                    return;
                }

                try {
                    const transaction = db.transaction(storeName, 'readonly');
                    const store = transaction.objectStore(storeName);

                    const keyPath = store.keyPath;

                    if (typeof keyPath === 'string') {
                        resolve(keyPath);
                    } else if (Array.isArray(keyPath)) {
                        resolve(keyPath.join(','));
                    } else {
                        resolve(null);
                    }
                } catch (error) {
                    console.error(`Error accessing object store "${storeName}":`, error);
                    resolve(null);
                }
            };

            request.onerror = (event) => {
                console.error(`Failed to open database: ${(event.target as IDBRequest).error}`);
                reject(null);
            };
        });
    }


    private formatDates<T>(value: T): T {
        const formattedObject = { ...value };

        for (const key in formattedObject) {
            const field = (formattedObject as any)[key];

            if (field instanceof Date || typeof field === 'string' && !isNaN(Date.parse(field))) {
                const date = field instanceof Date ? field : new Date(field);
                (formattedObject as any)[key] = DateHelper.formatDate(date,"YYYY-MM-DD HH:mm:ss");
            }
        }

        return formattedObject;
    }
}

const databaseVersion = Math.max(...Object.keys(migrations).map(Number));
const databaseService = new DatabaseService({
    dbName: 'psycho-app',
    version: databaseVersion || 1,
    stores: {},
});

export default databaseService;
