import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {FileDto} from "../models/dto/file-dto.interface";
import databaseService from "../indexedDB.service";

export class FileRepository extends BaseRepository<FileDto> {

    constructor() {
        super(IndexedDBStores.FILE);
    }

    async getFileById(id: number): Promise<FileDto> {
        return await databaseService.getByKeyPath<FileDto>(this.storeName, id).then(res => res.data as FileDto)
    }

}
