import {UserChapterRepository} from "../repositories/user-chapter.repository";
import AuthService from "../../../auth/services/auth.service";

export class UserChapterService {
    private userChapterRepository: UserChapterRepository;

    constructor() {
        this.userChapterRepository = new UserChapterRepository();
    }

    /**
     * Adds a new user chapter entity if it does not already exist.
     *
     * @param chapterId - The ID of the chapter to be added for the authenticated user.
     * @throws Will throw an error if the chapter cannot be added due to repository errors or missing authentication.
     */
    public async addEntityIfNotExists(chapterId: number): Promise<void> {
        try {
            const isExists: boolean = await this.userChapterRepository.hasChapterIdForUserId(chapterId);
            const userId: string = AuthService.authenticatedUser?.id!;

            if (!isExists && chapterId && userId) {
                await this.userChapterRepository.createEntity({
                    chapter_id: chapterId,
                    user_id: Number(userId),
                    completed: 0,
                    manual_completed: 0,
                });
            }
        } catch (err: any) {
            throw new Error("UserChapterService: Error occurred while adding the entity. Please try again.");
        }
    }
}
