export enum IndexedDBStores {
    NOTES = 'notes',
    USER = 'user',
    AREA = 'area',
    BLOCK = 'block',
    BLOCK_CONTENT = 'block_content',
    SUBAREA = 'subarea',
    CHAPTER = 'chapter',
    CALENDAR = 'calendar',
    FILE = 'file',
    SUBSCRIPTION = 'subscription',
    TRANSACTION_SUBSCRIPTION = 'transaction_subscriptions',
    TRANSACTION_COURSES = 'transaction_courses',
    URGENT_HELP = 'urgent_help',
    USER_AREA = 'user_area',
    USER_BLOCK = 'user_block',
    USER_BLOCK_CONTENT = 'user_block_content',
    USER_CHAPTER = 'user_chapter',
    USER_SUBAREA = 'user_subarea',
}
