import { BaseRepository } from "./base-repository";
import { IndexedDBStores } from "../models/enums/indexeddb-stores.enum";
import { BlockDto } from "../models/dto/block-dto.interface";
import {BlockDTO as BlocksResponse} from "../../../view-builder/types/get-blocks-response.interface";
import {ChapterRepository} from "./chapter.repository";

export class BlockRepository extends BaseRepository<BlockDto> {

    constructor() {
        super(IndexedDBStores.BLOCK);
    }

    /**
     * Get all blocks for a specific chapter ID
     * @param chapterId - ID of the chapter
     * @returns Promise<BlockDto[]>
     * @throws Error if no blocks are found for the chapter
     */
    async getBlocksByChapterId(chapterId: number): Promise<BlockDto[]> {
        const blocks: BlockDto[] = await this.getAllEntities();

        const chapterBlocks = blocks.filter(block => block.chapter_id === chapterId);

        if (chapterBlocks.length === 0) {
            throw new Error(`No blocks found for chapter with ID: ${chapterId}`);
        }

        return chapterBlocks;
    }

    /**
     * Get only interactive blocks for a specific chapter
     * @returns Promise<BlocksResponse[]> - List of interactive blocks
     */
    async getInteractiveBlocksForChapter(chapterId: number): Promise<BlockDto[]> {
        const response: BlockDto[] = await this.getAllEntities();
        return response
            .filter(block => block.chapter_id === chapterId)
            .filter(block => block.block_interactive);
    }
}
