import {AreaSyncData, EntityAppSync, UserSyncData} from "../models/sync-body.interface";
import {CalendarDto} from "../models/dto/calendar-dto.interface";
import {UserDto} from "../models/dto/user-dto.interface";

type BackendSyncData = AreaSyncData | CalendarDto[] | UserSyncData;

export const mapBackendSyncDataToEntitiesDto = (data: BackendSyncData): Record<string, EntityAppSync<any> | null> => {

    return {
        area: (data as AreaSyncData)?.areas?.length ? {entityDto: (data as AreaSyncData).areas} : null,
        subarea: (data as AreaSyncData)?.subareas?.length ? {entityDto: (data as AreaSyncData).subareas} : null,
        chapter: (data as AreaSyncData)?.chapters?.length ? {entityDto: (data as AreaSyncData).chapters} : null,
        block: (data as AreaSyncData)?.blocks?.length ? {entityDto: (data as AreaSyncData).blocks} : null,
        block_content: (data as AreaSyncData)?.blockContents?.length ? {entityDto: (data as AreaSyncData).blockContents} : null,
        user_area: (data as AreaSyncData)?.userAreas?.length ? {entityDto: (data as AreaSyncData).userAreas} : null,
        user_subarea: (data as AreaSyncData)?.userSubareas?.length ? {entityDto: (data as AreaSyncData).userSubareas} : null,
        user_chapter: (data as AreaSyncData)?.userChapters?.length ? {entityDto: (data as AreaSyncData).userChapters} : null,
        user_block: (data as AreaSyncData)?.userBlocks?.length ? {entityDto: (data as AreaSyncData).userBlocks} : null,
        user_block_content: (data as AreaSyncData)?.userBlockContents?.length ? {entityDto: (data as AreaSyncData).userBlockContents} : null,

        file: (data as AreaSyncData)?.files?.length
            ? {entityDto: (data as AreaSyncData).files}
            : (data as UserSyncData)?.file && !isFileEmpty((data as UserSyncData).file)
                ? {entityDto: [(data as UserSyncData).file]}
                : null,

        calendar: (data as CalendarDto[])?.length ? {entityDto: (data as CalendarDto[])} : null,

        user: (data as UserSyncData).user ? {entityDto: [(data as UserSyncData).user]} : null,

        subscription: (data as any)?.subscriptions?.length ? {entityDto: (data as any).subscriptions} : null,
        subscription_element: (data as any)?.subscriptionElements?.length ? {entityDto: (data as any).subscriptionElements} : null,
        subscription_expiry_date: (data as any)?.subscriptionExpiryDates?.length ? {entityDto: (data as any).subscriptionExpiryDates} : null,
        transaction: (data as any)?.transactions?.length ? {entityDto: (data as any).transactions} : null,
        urgent_help: (data as any)?.urgentHelps?.length ? {entityDto: (data as any).urgentHelps} : null,
        user_course: (data as any)?.userCourses?.length ? {entityDto: (data as any).userCourses} : null,
        user_subscription: (data as any)?.userSubscriptions?.length ? {entityDto: (data as any).userSubscriptions} : null,
    };
};


const isFileEmpty = (file: Record<string, any>): boolean => {
    return Object.values(file).every(value => value === null || value === undefined || value === '');
};
