import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {ChapterDto} from "../models/dto/chapter-dto.interface";
import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";
import {ChapterIsCompleted} from "../../../dashboard/types/get-chapter-is-completed.interface";

export class ChapterRepository extends BaseRepository<ChapterDto> {

    constructor() {
        super(IndexedDBStores.CHAPTER);
    }

    /**
     * Get a chapter by its name
     * @param name - Name of the chapter
     * @returns Promise<ChapterDto>
     * @throws Error if no chapter is found with the specified name
     */
    async getChapterByName(name: string): Promise<ChapterDto> {
        const chapters: ChapterDto[] = await this.getAllEntities();
        const chapter = chapters.find(chapter => chapter.name === name);
        if (!chapter) {
            throw new Error(`Chapter with name '${name}' not found.`);
        }

        return chapter;
    }

    /**
     * Get all chapters by subarea ID
     * @param subareaId - ID of the subarea
     * @returns Promise<ChapterDto[]> - List of chapters belonging to the specified subarea
     */
    async getChaptersBySubareaId(subareaId: number): Promise<ChapterDto[]> {
        const chapters: ChapterDto[] = await this.getAllEntities();
        return chapters?.filter(chapter => chapter.subarea_id === subareaId) || [];
    }

    /**
     * Get active user view for a specific chapter.
     * @param chapterId - ID of the chapter.
     * @returns Promise<ChaptersIsActiveUserView[]>
     */
    async getChapterIsCompletedForUser(chapterId: number): Promise<Array<ChapterIsCompleted>> {
        const userChapters: UserChapterDto[] = await new BaseRepository<UserChapterDto>(IndexedDBStores.USER_CHAPTER).getAllEntities();

        const isChapterCompleted: boolean = userChapters.some(
            (uc) => uc.chapter_id === chapterId && uc.completed == 1
        );

        return [
            {
                chapterId: chapterId,
                isActive: isChapterCompleted,
            },
        ];
    }
}
