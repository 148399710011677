import {CalendarService} from "../services/calendar.service";
import {
    CalendarEventRequestBody as CalendarBodyRequest
} from "../../../calendar/types/calendar-event-request-body.interface";
import {GetCalendarEntity} from "../../../calendar/types/get-calendar-entity.interface";
import {ConfigController} from "../models/config-controller.interface";
import NotificationService from "../../../common/services/notification.service";

export class CalendarController {
    private calendarService: CalendarService;

    constructor() {
        this.calendarService = new CalendarService();
    }

    async getUserCalendars(startDate: string, endDate: string, config?: ConfigController): Promise<Array<GetCalendarEntity>> {
        try {
            const res = await this.calendarService.getAllCalendars(startDate, endDate);
            config?.successMessage && NotificationService.success(config.successMessage, "");
            return res
        } catch (error: any) {
            console.error("Failed to createEntity calendar:", error.message);
            config?.errorMessage && NotificationService.error(config.errorMessage, error);
            throw error
        }
    }

    async createTaskOrEventCalendar(data: CalendarBodyRequest,config?: ConfigController): Promise<void> {
        try {
            await this.calendarService.createCalendar(data);
            if (config?.successMessage) {
                NotificationService.success(config.successMessage);
            }
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            console.error("Failed to createEntity calendar:", error.message);
            throw error
        }
    }

    async updateTaskOrEventCalendar(id: string, data: CalendarBodyRequest,config?: ConfigController): Promise<void> {
        try {
            await this.calendarService.updateCalendar(id, data);
            if (config?.successMessage) {
                NotificationService.success(config.successMessage);
            }
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            console.error("Failed to createEntity calendar:", error.message);
            throw error
        }
    }

    async deleteTaskOrEventCalendar(id: string,config?: ConfigController): Promise<void> {
        try {
            await this.calendarService.deleteCalendar(id);
            if (config?.successMessage) {
                NotificationService.success(config.successMessage);
            }
            console.log(`Calendar with ID ${id} deleted successfully`);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            console.error("Failed to deleteEntity calendar:", error.message);
            throw error
        }
    }

    async markAsCompleted(id: string,config?: ConfigController): Promise<void> {
        try {
            await this.calendarService.markAsCompleted(id);
            console.log(`Calendar with ID ${id} deleted successfully`);
            if (config?.successMessage) {
                NotificationService.success(config.successMessage);
            }
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            console.error("Failed to deleteEntity calendar:", error.message);
            throw error
        }
    }

}
