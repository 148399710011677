import {SubareaRepository} from "../repositories/subarea.repository";
import {ChapterRepository} from "../repositories/chapter.repository";
import {ChapterDto} from "../models/dto/chapter-dto.interface";
import {SubareaDto} from "../models/dto/subarea-dto.interface";
import {ElementListChapter} from "../../../dashboard/types/chapter.interface";
import {UserChapterService} from "./user-chapter.service";
import {UserChapterRepository} from "../repositories/user-chapter.repository";
import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";
import {BlockRepository} from "../repositories/block.repository";
import {BlockDto} from "../models/dto/block-dto.interface";
import {UserBlockDto} from "../models/dto/user-block-dto.interface";
import {UserBlockRepository} from "../repositories/user-block.repository";
import {UserBlockContentRepository} from "../repositories/user-block-content.repository";
import {UserBlockContentDto} from "../models/dto/user-block-content-dto.interface";
import {GetChapterIsCompleted} from "../../../dashboard/types/get-chapter-is-completed.interface";

export class ChapterService {
    private chapterRepository: ChapterRepository;
    private subareaRepository: SubareaRepository;
    private userChapterService: UserChapterService;
    private userChapterRepository: UserChapterRepository;
    private blockRepository: BlockRepository;
    private userBlockRepository: UserBlockRepository;
    private userBlockContentRepository: UserBlockContentRepository;


    constructor() {
        this.subareaRepository = new SubareaRepository();
        this.chapterRepository = new ChapterRepository();
        this.userChapterService = new UserChapterService();
        this.userChapterRepository = new UserChapterRepository();
        this.blockRepository = new BlockRepository();
        this.userBlockRepository = new UserBlockRepository();
        this.userBlockContentRepository = new UserBlockContentRepository();
    }

    async getChaptersForUser(subareaName: string): Promise<Array<ElementListChapter>> {
        const subarea: SubareaDto = await this.subareaRepository.getSubareaByName(subareaName);
        const chapters: ChapterDto[] = await this.chapterRepository.getAllEntities();
        const response: ElementListChapter[] = []


        for (const chapter of chapters) {
            let userChapter = await this.userChapterRepository.getEntityById(chapter.id);

            if (subarea.id === chapter.subarea_id) {
                response.push({
                    id: chapter.id,
                    name: chapter.name,
                    ordinalNumber: chapter.ordinal_number!,
                    isCompleted: !!userChapter?.completed,
                    manualCompleted: !!userChapter?.manual_completed,
                })
            }
        }

        return response;
    }

    public async createUserChapterForm(chapterId: number): Promise<void> {
        await this.userChapterService.addEntityIfNotExists(chapterId);
    }

    /**
     * Checks if a chapter is completed for the authenticated user.
     * @param chapterName - The name of the chapter.
     * @returns Promise<Array<ChaptersIsActiveUserView>>
     */
    async getChapterIsCompleted(chapterName: string): Promise<GetChapterIsCompleted> {
        const chapter = await this.chapterRepository.getChapterByName(chapterName);

        if (!chapter) {
            throw new Error('Chapter not found');
        }

        const existsInUserChapter = await this.userChapterRepository.getEntityById(chapter.id);

        if (!existsInUserChapter) {
            return [
                { chapterId: chapter.id, isActive: false }
            ];
        }

        const result = await this.chapterRepository.getChapterIsCompletedForUser(chapter.id);

        if (result.length === 0) {
            result.push({ chapterId: chapter.id, isActive: false });
        }

        return result;
    }

    /**
     * Updates the manual completion status of a chapter for the authenticated user.
     * If the chapter is marked as incomplete, the user's progress is reset.
     *
     * @param chapterId - The ID of the chapter to update.
     * @param value - Boolean value indicating whether the chapter is manually completed.
     */
    public async changeManualCompleted(chapterId: number, value: boolean): Promise<void> {
        const userChapter: UserChapterDto | undefined = await this.userChapterRepository.getEntityById(chapterId);

        if (userChapter) {
            let updatedUserChapter: UserChapterDto = {
                ...userChapter!,
            }
            if (value) {
                updatedUserChapter.manual_completed = 1
            } else {
                updatedUserChapter.manual_completed = 0
                await this.resetUserProgress(chapterId);
            }
            await this.userChapterRepository.updateEntity(updatedUserChapter);
        }
    }

    /**
     * Resets the user's progress for a given chapter and updates related statuses.
     *
     * @param chapterId - The ID of the chapter to reset progress for.
     */
    public async resetUserProgress(chapterId: number): Promise<void> {
        await this.deleteUserBlocksByChapterId(chapterId);
        await this.deleteUserBlockContentsByChapterId(chapterId);

        const userChapter = await this.userChapterRepository.getEntityById(chapterId);
        if (userChapter) {
            let updatedUserChapter: UserChapterDto = {
                ...userChapter!,
            }
            userChapter.manual_completed = 0;
            await this.userChapterRepository.updateEntity(updatedUserChapter);
        }

        //TODO wywołuje to po resecie w komponencie
        // await this.areaService.updateCompletionStatus();
    }

    /**
     * Deletes user blocks by chapter ID and user ID.
     * @param chapterId - ID of the chapter.
     */
    private async deleteUserBlocksByChapterId(chapterId: number): Promise<void> {
        const allBlocksForChapter: BlockDto[] = await this.blockRepository.getBlocksByChapterId(chapterId);
        const allUserBlocks: UserBlockDto[] = await this.userBlockRepository.getAllEntities();

        const userBlocksToDelete: UserBlockDto[] = allUserBlocks.filter(userBlock =>
            allBlocksForChapter.some(block => block.id === userBlock.block_id)
        );

        await Promise.all(
            userBlocksToDelete.map(userBlock => this.userBlockRepository.deleteEntity(userBlock.block_id))
        );
    }


    /**
     * Deletes user block contents by chapter ID and user ID.
     * @param chapterId - ID of the chapter.
     */
    private async deleteUserBlockContentsByChapterId(chapterId: number): Promise<void> {
        const allBlocksForChapter: BlockDto[] = await this.blockRepository.getBlocksByChapterId(chapterId);
        const allUserBlockContentsForChapter: UserBlockContentDto[] = (
            await Promise.all(
                allBlocksForChapter.map((block) =>
                    this.userBlockContentRepository.getAllEntitiesByBlockId(block.id)
                )
            )
        ).flat();

        await Promise.all(
            allUserBlockContentsForChapter.map((userBlockContent) =>
                this.userBlockContentRepository.deleteEntity(userBlockContent.block_content_id)
            )
        );
    }

}
