import {AreaSyncData, UserSyncData} from "./models/sync-body.interface";
import databaseService from "./indexedDB.service";
import ApiService from "../services/api.service";
import {mapBackendSyncDataToEntitiesDto} from "./helpers/map-backend-sync-data-to-entities-dto";
import {SyncAreasPutRequest} from "./models/interfaces/sync-areas-put-request.interface";
import {IndexedDBStores} from "./models/enums/indexeddb-stores.enum";
import {SyncPutMapper} from "./helpers/sync-put-mapper";
import {UserAreaDto} from "./models/dto/user-area-dto.interface";
import {UserSubareaDto} from "./models/dto/user-subarea-dto.interface";
import {UserChapterDto} from "./models/dto/user-chapter-dto.interface";
import {UserBlockDto} from "./models/dto/user-block-dto.interface";
import {UserBlockContentDto} from "./models/dto/user-block-content-dto.interface";
import {SyncCalendarPutRequest} from "./models/interfaces/sync-calendar-put-request.interface";
import {CalendarDto} from "./models/dto/calendar-dto.interface";
import {UrgentHelpDto} from "./models/dto/urgent-help-dto.interface";
import {UrgentHelpService} from "./services/urgent-help.service";
import {UserDto} from "./models/dto/user-dto.interface";
import {UserPutRequest} from "./models/interfaces/sync-user-put-request.interface";
import {FileDto} from "./models/dto/file-dto.interface";
import {CoursesHistoryTransaction} from "../../dashboard/types/courses-history-transaction.interface";
import {SubscriptionHistoryTransaction} from "../../subscriptions/types/subscriptions-history-transaction";

class SyncService extends ApiService {
    public async synchronizeApp(): Promise<void> {
        await this.syncAreaModule();
        await this.syncCalendarModule();
        await this.syncUrgentHelpModule();
        await this.syncUserModule();
        await this.syncTransactionsModule();
    }

    /**
     * Synchronizes area data by fetching it from the server and storing it in IndexedDB.
     */
    public async syncUrgentHelpModule() {
        try {
            await this.getServerDataSyncUrgentHelp().then(async data => await new UrgentHelpService().setUrgentHelp(data));
        } catch (error: any) {
            console.error("Failed to synchronize urgent help data:", error.message);
            throw error;
        }
    }

    private async getServerDataSyncUrgentHelp(): Promise<UrgentHelpDto> {
        try {
            return await this.get<UrgentHelpDto>('urgent-help')
        } catch (error: any) {
            console.error("Failed to fetch urgent help sync data:", error.message);
            throw error;
        }
    }

    public async syncAreaModule(): Promise<void> {
        try {
            const localAreaData: SyncAreasPutRequest = await this.getLocalAreaData();
            await this.putDataAreasToServer(localAreaData);

            const data: AreaSyncData = await this.getServerDataSyncAreas();
            const entities = mapBackendSyncDataToEntitiesDto(data);

            for (const [storeName, data] of Object.entries(entities)) {
                if (data && data.entityDto && Array.isArray(data.entityDto)) {
                    await this.syncRecordsToIndexedDB(storeName, data.entityDto);
                }
            }

            console.log("Area data successfully synchronized in app.");
        } catch (error: any) {
            console.error("Failed to synchronize area data:", error.message);
            throw error;
        }
    }

    public async syncCalendarModule(): Promise<void> {
        try {
            const localCalendarData: SyncCalendarPutRequest = await this.getLocalCalendarData();
            await this.putDataCalendarToServer(localCalendarData);

            const data: CalendarDto[] = await this.getServerDataSyncCalendar();
            const entities = mapBackendSyncDataToEntitiesDto(data);

            for (const [storeName, data] of Object.entries(entities)) {
                if (data && data.entityDto && Array.isArray(data.entityDto)) {
                    await this.syncRecordsToIndexedDB(storeName, data.entityDto);
                }
            }

            console.log("Calendar data successfully synchronized in app.");
        } catch (error: any) {
            console.error("Failed to synchronize calendar data to server:", error.message);
            throw error;
        }
    }

    public async syncUserModule(): Promise<void> {
        try {
            const localUserData: UserPutRequest | null = await this.getLocalUserData();
            await this.putDataUserToServer(localUserData);

            const data: UserSyncData = await this.getServerDataSyncUser();
            const entities = mapBackendSyncDataToEntitiesDto(data);

            for (const [storeName, data] of Object.entries(entities)) {
                if (data && data.entityDto && Array.isArray(data.entityDto)) {
                    await this.syncRecordsToIndexedDB(storeName, data.entityDto);
                }
            }

            console.log("User data successfully synchronized in app.");
        } catch (error: any) {
            console.error("Failed to synchronize user data to server:", error.message);
            throw error;
        }
    }

    public async syncTransactionsModule(): Promise<void> {
        try {
            const subTransactions: SubscriptionHistoryTransaction = await this.getServerDataSyncSubscriptionTransactions();
            const courseTransactions: CoursesHistoryTransaction = await this.getServerDataSyncCoursesTransactions();
            const entries = {
                transaction_subscriptions: {entityDto: subTransactions.subscriptions},
                transaction_courses: {entityDto: courseTransactions.courses},
            }
            console.log(entries)

            for (const [storeName, data] of Object.entries(entries)) {
                if (data && data.entityDto && Array.isArray(data.entityDto)) {
                    await this.syncRecordsToIndexedDB(storeName, data.entityDto);
                }
            }

            console.log("User transactions successfully synchronized in app.");
        } catch (error: any) {
            console.error("Failed to synchronize transactions data to server:", error.message);
            throw error;
        }
    }

    /**
     * Fetches synchronization data for areas.
     * @returns Promise<AreaSyncData> - Area synchronization data.
     * @throws Error if the request fails.
     * @private
     */
    private async getServerDataSyncAreas(): Promise<AreaSyncData> {
        try {
            return await this.get<AreaSyncData>('sync/areas', {
                skipDefault404Handling: true,
                skipNotifications: true,
            })
        } catch (error: any) {
            console.error("Failed to fetch areas sync data:", error.message);
            throw error;
        }
    }

    private async getServerDataSyncCalendar(): Promise<CalendarDto[]> {
        try {
            return await this.get<Array<CalendarDto>>('/calendars/all-user-tasks-events', {
                params: {
                    startDate: "1900-01-01",
                    endDate: "2200-01-01",
                },
                skipDefault404Handling: true,
                skipNotifications: true,
            })
        } catch (error: any) {
            console.error("Failed to fetch calendar sync data:", error.message);
            throw error;
        }
    }

    private async getServerDataSyncUser(): Promise<UserSyncData> {
        try {
            return await this.get<UserSyncData>('/sync/user-data', {
                skipDefault404Handling: true,
                skipNotifications: true,
            })
        } catch (error: any) {
            console.error("Failed to fetch user sync data:", error.message);
            throw error;
        }
    }

    private async getServerDataSyncCoursesTransactions(): Promise<CoursesHistoryTransaction> {
        try {
            return await this.get<CoursesHistoryTransaction>('/areas/user-courses-history', {})
                .then(res => res || [])
        } catch (error: any) {
            console.error("Failed to fetch courses transaction sync data:", error.message);
            throw error;
        }
    }

    private async getServerDataSyncSubscriptionTransactions(): Promise<SubscriptionHistoryTransaction> {
        try {
            return await this.get<SubscriptionHistoryTransaction>('/subscriptions/user-subscriptions-history', {})
                .then(res => res || [])
        } catch (error: any) {
            console.error("Failed to fetch courses transaction sync data:", error.message);
            throw error;
        }
    }

    private async putDataAreasToServer(syncData: SyncAreasPutRequest): Promise<void> {
        try {
            await this.put("sync/areas", syncData, {
                skipDefault404Handling: true,
                skipNotifications: true,
            });
            console.log("Area data successfully synchronized to server.");
        } catch (error: any) {
            console.error("Failed to synchronize area data to server:", error.message);
            throw error;
        }
    }

    private async putDataCalendarToServer(syncData: SyncCalendarPutRequest): Promise<void> {
        try {
            await this.put("sync/calendar", {...syncData}, {
                skipDefault404Handling: true,
                skipNotifications: true,
            });
            console.log("Calendar data successfully synchronized to server.");
        } catch (error: any) {
            console.error("Failed to synchronize calendar data to server:", error.message);
            throw error;
        }
    }

    private async putDataUserToServer(syncData: UserPutRequest | null): Promise<void> {
        try {
            await this.put("sync/user-data", syncData, {
                headers: {
                    "Content-Type": "application/json"
                },
                skipDefault404Handling: true,
                skipNotifications: true,
            });
            console.log("User data successfully synchronized to server.");
        } catch (error: any) {
            console.error("Failed to synchronize calendar user to server:", error.message);
            throw error;
        }
    }

    /**
     * Private method for saving records to IndexedDB.
     * @param storeName - The name of the store in IndexedDB.
     * @param records - Array of records to save.
     */
    private async syncRecordsToIndexedDB(storeName: string, records: Array<any>): Promise<void> {
        try {
            const keyPath = await databaseService.getKeyPathForStore(storeName);

            if (!keyPath) {
                console.error(`KeyPath is not defined for store: ${storeName}`);
                return;
            }

            const localRecords = await databaseService.getAll(storeName).then(res => res.data);
            const localRecordMap = new Map(localRecords.map((record: any) => [record[keyPath], record]));

            const backendRecordMap = new Map(records.map((record) => [record[keyPath], record]));


            for (const record of records) {
                const localRecord = localRecordMap.get(record[keyPath]);
                // if (localRecord == undefined) continue;
                if (!localRecord) {
                    await databaseService.add(storeName, record);
                } else if (!deepEqual(localRecord, record)) {
                    await databaseService.update(storeName, record);
                }
            }

            localRecordMap.forEach((_value, localKey) => {
                if (localKey && !backendRecordMap.has(localKey)) {
                    databaseService.delete(storeName, localKey).catch((error) =>
                        console.error(`Failed to delete record: ${error.message}`)
                    );
                }
            });
        } catch (error: any) {
            console.error(`Failed to sync store "${storeName}":`, error.message);
        }
    }

    private async getLocalAreaData(): Promise<SyncAreasPutRequest> {
        const userAreas = await databaseService
            .getAll<UserAreaDto>(IndexedDBStores.USER_AREA)
            .then(res => SyncPutMapper.mapUserAreaDtosToPutRequests(res.data));

        const userSubareas = await databaseService
            .getAll<UserSubareaDto>(IndexedDBStores.USER_SUBAREA)
            .then(res => SyncPutMapper.mapUserSubareaDtosToPutRequests(res.data));

        const userChapters = await databaseService
            .getAll<UserChapterDto>(IndexedDBStores.USER_CHAPTER)
            .then(res => SyncPutMapper.mapUserChapterDtosToPutRequests(res.data));

        const userBlocks = await databaseService
            .getAll<UserBlockDto>(IndexedDBStores.USER_BLOCK)
            .then(res => SyncPutMapper.mapUserBlockDtosToPutRequests(res.data));

        const userBlockContents = await databaseService
            .getAll<UserBlockContentDto>(IndexedDBStores.USER_BLOCK_CONTENT)
            .then(res => SyncPutMapper.mapUserBlockContentDtosToPutRequests(res.data));

        return {
            userAreas,
            userSubareas,
            userChapters,
            userBlocks,
            userBlockContents,
        };
    }

    private async getLocalCalendarData(): Promise<SyncCalendarPutRequest> {
        const calendar = await databaseService
            .getAll<CalendarDto>(IndexedDBStores.CALENDAR)
            .then(res => SyncPutMapper.mapCalendarDtosToUpdateRequests(res.data));

        return {records: calendar};
    }

    private async getLocalUserData(): Promise<UserPutRequest | null> {
        const userRes = await databaseService.getAll<UserDto | null>(IndexedDBStores.USER);

        if (userRes.data.length) {
            const user = userRes.data[0]!;

            const fileRes = await databaseService.getAll<FileDto>(IndexedDBStores.FILE);
            const fileDto = fileRes.data.find(el => el.id === user.profile_picture_id);

            return SyncPutMapper.mapUserDtoToUpdateRequest(user, fileDto);
        }

        return null;
    }

}

export default new SyncService();

function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (
        typeof obj1 !== 'object' || obj1 === null ||
        typeof obj2 !== 'object' || obj2 === null
    ) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}
