import ApiService from "../../core/services/api.service";
import {GetMainAreasResponse} from "../types/get-main-areas.interface";
import {MainArea} from "../types/main-area.interface";
import {ElementListSubarea, Subarea} from "../types/sub-area.interface";
import {Chapter, ElementListChapter} from "../types/chapter.interface";
import {GetChaptersResponse} from "../types/get-chapters.interface";
import {Course} from "../../courses/types/course.interface";
import {CoursesHistoryTransaction, CourseTransaction} from "../types/courses-history-transaction.interface";
import {GetChapterIsCompleted} from "../types/get-chapter-is-completed.interface";
import {CalendarController} from "../../core/db/controllers/calendar.controller";
import {AreaController} from "../../core/db/controllers/area.controller";
import {SubareaController} from "../../core/db/controllers/subarea.controller";
import {ChapterController} from "../../core/db/controllers/chapter.controller";
import {TransactionController} from "../../core/db/controllers/transaction.controller";

class AreaApiService extends ApiService {
    private readonly areaController = new AreaController();
    private readonly subareaController = new SubareaController();
    private readonly chapterController = new ChapterController();
    private readonly transactionController = new TransactionController();


    constructor() {
        super();
    }

    public async getMainAreas(): Promise<Array<MainArea>> {
        return this.areaController.getAreasForUser().then(res => res || []);
        // return await this.get<GetMainAreasResponse>('/areas/areas-for-user', {})
        //     .then(res => res || [])
    }

    public async getSubAreas(areaName: string): Promise<Array<ElementListSubarea>> {
        return this.subareaController.getSubareasForUser({
            params: {areaName}
        }).then(res => res || []);
        // return await this.get<Array<ElementListSubarea>>(`/areas/subareas-for-user`, {
        //     params: {areaName}
        // }).then(res => res || [])
    }

    public async getChapters(subareaName: string): Promise<Array<ElementListChapter>> {
        return this.chapterController.getChaptersForUser({
            params: {subareaName}
        }).then(res => res || []);
        // return await this.get<GetChaptersResponse>(`/chapters/chapters-for-user`, {
        //     params: {subareaName}
        // }).then(res => res || [])
    }

    public async getCoursesHistoryTransaction(): Promise<CourseTransaction[]> {
        return await this.transactionController.getCourseTransactions();
        // return await this.get<CoursesHistoryTransaction>('/areas/user-courses-history', {})
        //     .then(res => res || [])
    }

    public async getCourse(areaName: string): Promise<Course> {
        return await this.areaController.getCourseWithSubareasAndChapters({
            params: {courseName: areaName}
        }).then(res => res)
        // return await this.get<Course>('/areas/courses', {
        //     params: {areaName}
        // })
        //     .then(res => res)
    }

    public async updateStatusAreas(): Promise<void> {
        return await this.areaController.updateUserCompletionStatus()
        // return await this.put<void>('/areas/update-user-completion-status', {}, {
        //     skipDefault404Handling: true
        // })
    }

    public async resetChapter(chapterId: number | string): Promise<void> {
        return await this.chapterController.resetUserProgress({
            params:{
                chapterId
            }
        })
        // return await this.delete<void>(`/chapters/${chapterId}/reset-progress`, {})
    }

    public async changeManualCompletedChapter(chapterId: number | string, value: boolean): Promise<void> {
        return await this.chapterController.changeManualCompleted({
            params:{
                chapterId,
                value,
            }
        })
        // return await this.put<void>(`/chapters/${chapterId}/manual-completed`, {}, {
        //     params: {value}
        // })
    }

    public async getChapterIsCompleted(chapterName: string): Promise<GetChapterIsCompleted> {
        return await this.chapterController.getChapterIsCompleted({
            params:{chapterName}
        }).then(res => res || false);
        // return await this.get<GetChapterIsCompleted>(`/chapters/is-completed`, {
        //     params: {chapterName}
        // }).then(res => res || false);
    }
}

export default new AreaApiService();
