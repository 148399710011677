import {ConfigController} from "../models/config-controller.interface";
import NotificationService from "../../../common/services/notification.service";
import {ChapterService} from "../services/chapter.service";
import {ElementListChapter} from "../../../dashboard/types/chapter.interface";

export class ChapterController {
    private chapterService: ChapterService;

    constructor() {
        this.chapterService = new ChapterService();
    }

    /**
     * Retrieves subareas for a user based on the provided subarea name.
     *
     * **Required Parameters in Config:**
     * - `subareaName` (string): The name of the subarea to fetch.
     */
    async getChaptersForUser(config?: ConfigController): Promise<Array<ElementListChapter>> {
        try {
            const subareaName: string | undefined = config?.params['subareaName'];
            if (!subareaName) {
                throw new Error("The parameter 'subareaName' is required.");
            }
            return await this.chapterService.getChaptersForUser(subareaName);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            throw error;
        }
    }

    /**
     * Resets the progress for a user in a specific chapter.
     *
     * **Required Parameters in Config:**
     * - `chapterId` (number): The ID of the chapter to reset progress for.
     */
    async resetUserProgress(config?: ConfigController): Promise<void> {
        try {
            const chapterId: number | undefined = config?.params['chapterId'];
            if (!chapterId) {
                throw new Error("The parameter 'chapterId' is required.");
            }
            await this.chapterService.resetUserProgress(chapterId);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            throw error;
        }
    }

    /**
     * Manually marks a chapter as completed or not completed.
     *
     * **Required Parameters in Config:**
     * - `chapterId` (number): The ID of the chapter to update.
     * - `value` (boolean): Whether to mark the chapter as completed.
     */
    async changeManualCompleted(config?: ConfigController): Promise<void> {
        try {
            const chapterId: number | undefined = config?.params['chapterId'];
            const value: boolean | undefined = config?.params['value'];
            if (chapterId === undefined || value === undefined) {
                throw new Error("The parameters 'chapterId' and 'value' are required.");
            }
            await this.chapterService.changeManualCompleted(chapterId, value);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            throw error;
        }
    }

    /**
     * Checks if a chapter is completed for the authenticated user.
     *
     * **Required Parameters in Config:**
     * - `chapterName` (string): The name of the chapter to check.
     */
    async getChapterIsCompleted(config?: ConfigController): Promise<Array<{ chapterId: number; isActive: boolean }>> {
        try {
            const chapterName: string | undefined = config?.params['chapterName'];
            if (!chapterName) {
                throw new Error("The parameter 'chapterName' is required.");
            }
            return await this.chapterService.getChapterIsCompleted(chapterName);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            throw error;
        }
    }

}
