import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import databaseService from "../indexedDB.service";
import {CourseTransaction} from "../../../dashboard/types/courses-history-transaction.interface";
import {SubscriptionTransaction} from "../../../subscriptions/types/subscriptions-history-transaction";

export class TransactionRepository {

    private readonly ts = IndexedDBStores.TRANSACTION_SUBSCRIPTION
    private readonly tc = IndexedDBStores.TRANSACTION_COURSES

    async getAllTransactionSub(): Promise<SubscriptionTransaction[]> {
        const res = await databaseService.getAll<SubscriptionTransaction>(this.ts)
        return res.data
    }

    async getAllTransactionCourse(): Promise<CourseTransaction[]> {
        const res = await databaseService.getAll<CourseTransaction>(this.tc)
        return res.data
    }

}
