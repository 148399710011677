import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {BlockContentDto} from "../models/dto/block-content-dto.interface";
import {UserBlockContentDto} from "../models/dto/user-block-content-dto.interface";
import {BlockContentRepository} from "./block-content.repository";

export class UserBlockContentRepository extends BaseRepository<UserBlockContentDto> {
    private blockContentRepository: BlockContentRepository;

    constructor() {
        super(IndexedDBStores.USER_BLOCK_CONTENT);
        this.blockContentRepository = new BlockContentRepository();
    }

    /**
     * Check if a UserBlockContent entity exists by blockContentId.
     * @param blockContentId - ID of the BlockContent
     * @returns Promise<boolean>
     */
    async isExistsByBlockContentId(blockContentId: number): Promise<boolean> {
        const allEntities: UserBlockContentDto[] = await this.getAllEntities();
        return !!allEntities.find(
            (userBlockContent) => userBlockContent.block_content_id === blockContentId
        );
    }

    async getAllEntitiesBlockContentId(blockContentId: number): Promise<UserBlockContentDto[]> {
        const allEntities: UserBlockContentDto[] = await this.getAllEntities();

        return allEntities.filter(
            (userBlockContent) => userBlockContent.block_content_id == blockContentId
        );
    }


    /**
     * Get all UserBlockContent entities by blockId.
     * Uses BlockContentRepository to resolve block_content_ids.
     * @param blockId - ID of the block
     * @returns Promise<UserBlockContentDto[]>
     */
    async getAllEntitiesByBlockId(blockId: number): Promise<UserBlockContentDto[]> {
        const blockContents = await this.blockContentRepository.getAllEntitiesByBlockId(blockId);
        if (!blockContents.length) {
            return [];
        }
        const blockContentIds = blockContents.map((content) => content.id);
        return this.getAllEntitiesByBlockContentIds(blockContentIds);
    }

    createPureUserBlockContent(blockContent: BlockContentDto, userId: number): UserBlockContentDto {
        return {
            user_id: userId,
            block_content_id: blockContent.id,
            pyramid_ordinal_number: null,
            selected: null,
            sentence: null,
        }
    }

    /**
     * Get all UserBlockContent by a list of BlockContent IDs.
     * Ignores deleted records.
     * @param blockContentIds - List of BlockContent IDs
     * @returns Promise<UserBlockContentDto[]>
     */
    private async getAllEntitiesByBlockContentIds(blockContentIds: number[]): Promise<UserBlockContentDto[]> {
        const allEntities: UserBlockContentDto[] = await this.getAllEntities();

        return allEntities.filter(
            (userBlockContent) =>
                blockContentIds.includes(userBlockContent.block_content_id)
        );
    }
}
