import {UserRepository} from "../repositories/user.repository";
import {FileRepository} from "../repositories/file.repository";
import {RequestBodyUploadProfileImage} from "../../types/request-body-upload-profile-image.interface";
import {RequestBodyUserData} from "../../types/request-body-user-data.interface";
import {GetUserData} from "../../types/get-user-data.interface";
import {FileDto} from "../models/dto/file-dto.interface";
import {DateHelper} from "../../utils/dateHelper";


export class UserService {
    private userRepository: UserRepository;
    private fileRepository: FileRepository;

    constructor() {
        this.userRepository = new UserRepository();
        this.fileRepository = new FileRepository();
    }

    async updateProfilePicture(fileUploadRequest: RequestBodyUploadProfileImage): Promise<void> {
        const user = {...(await this.userRepository.getAllEntities().then(res => res[0]))}

        if (!fileUploadRequest.contentBase64) {
            if (user.profile_picture_id) {
                await this.fileRepository.deleteEntity(user.profile_picture_id);
            }
            user.profile_picture_id = null;
        } else {
            const binaryString = atob(fileUploadRequest.contentBase64);
            const decodedBytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                decodedBytes[i] = binaryString.charCodeAt(i);
            }

            if (decodedBytes.length > 1_050_000) {
                throw new Error("Rozmiar zdjęcia profilowego przekracza limit 1 MB.");
            }

            if (user.profile_picture_id) {
                const existingFile: FileDto | undefined = await this.fileRepository.getFileById(user.profile_picture_id);

                const updatedFile: FileDto = {
                    ...existingFile,
                    name: fileUploadRequest.name || "profile-image",
                    base64: fileUploadRequest.contentBase64,
                };
                await this.fileRepository.updateEntity(updatedFile);

            } else {
                const newFile: Omit<FileDto, 'id'> = {
                    base64: fileUploadRequest.contentBase64,
                    name: fileUploadRequest.name || "profile-image",
                    created: DateHelper.formatDate(new Date(), "YYYY-MM-DDTHH:mm:ss"),
                    extension: this.getFileExtension(fileUploadRequest.contentBase64) || "jpg",
                    size_in_bytes: this.getFileSize(fileUploadRequest.contentBase64),
                };

                user.profile_picture_id = await this.fileRepository.createEntity(newFile) as number
            }
        }

        await this.userRepository.updateEntity(user);
    }


    async updateUserData(userDataPutRequest: RequestBodyUserData): Promise<void> {
        const user = await this.userRepository.getAllEntities().then(res => res[0])

        user.firstname = userDataPutRequest.firstname;
        user.lastname = userDataPutRequest.lastname;
        user.profession = userDataPutRequest.profession;
        user.gender = userDataPutRequest.gender;

        await this.userRepository.updateEntity(user);
    }

    async getUserData(): Promise<GetUserData> {
        const user = await this.userRepository.getAllEntities().then(res => res[0])

        if (!user) {
            throw new Error("User not found");
        }

        let contentBase64 = null;
        if (user.profile_picture_id) {
            const file = await this.fileRepository.getFileById(user.profile_picture_id);
            contentBase64 = file.base64;
        }

        console.log({
            ...user,
            contentBase64: contentBase64!,
        })
        return {
            ...user,
            contentBase64: contentBase64!,
        };
    }

    private getFileExtension(base64String: string): string | null {
        const match = base64String.match(/^data:(.*?);base64,/);
        if (match && match[1]) {
            const mimeType = match[1];
            return mimeType.split('/')[1]; // Extract the part after "image/"
        }
        return null; // No MIME type found
    }

    private getFileSize(base64String: string): number {
        const base64Data = base64String.split(',')[1] || base64String; // Remove MIME type prefix if present
        const paddingLength = (base64Data.match(/=/g) || []).length;
        const base64Length = base64Data.length;
        return (base64Length * 3) / 4 - paddingLength;
    }
}
