import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {SubareaDto} from "../models/dto/subarea-dto.interface";
import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";
import {ChapterRepository} from "./chapter.repository";
import {UserChapterRepository} from "./user-chapter.repository";
import {ChapterDto} from "../models/dto/chapter-dto.interface";

export class SubareaRepository extends BaseRepository<SubareaDto> {

    private chapterRepository: ChapterRepository
    private userChapterRepository: UserChapterRepository

    constructor() {
        super(IndexedDBStores.SUBAREA);
        this.chapterRepository = new ChapterRepository();
        this.userChapterRepository = new UserChapterRepository();
    }

    /**
     * Get an area by its name
     * @param name - Name of the area
     * @returns Promise<SubareaDto>
     * @throws Error if no area is found
     */
    async getSubareaByName(name: string): Promise<SubareaDto> {
        const subareas: SubareaDto[] = await this.getAllEntities();
        const subarea: SubareaDto | undefined = subareas.find((subarea) => subarea.name === name);
        if (!subarea) {
            throw new Error(`Nie ma aktywnego podobszaru o nazwie: ${name}`);
        }

        return subarea;
    }

    /**
     * Get subareas by area ID
     * @param areaId - ID of the area
     * @returns Promise<Array<SubareaDto>>
     */
    async getSubareasByAreaId(areaId: number): Promise<Array<SubareaDto>> {
        const subareas: SubareaDto[] = await this.getAllEntities();
        return subareas.filter(subarea => subarea.area_id === areaId);
    }

    async getAllUserChaptersBySubareaId(subareaId: number): Promise<UserChapterDto[] | undefined> {
        const chapters: ChapterDto[] = await this.chapterRepository.getChaptersBySubareaId(subareaId);
        const userChapters: (UserChapterDto | undefined)[] = await Promise.all(
            chapters.map(async (chapter) => {
                return await this.userChapterRepository.getEntityById(chapter.id);
            })
        );

        return userChapters.filter((userChapter) => userChapter !== undefined) as UserChapterDto[];
    }

}
