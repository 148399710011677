import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {SubscriptionDto} from "../models/dto/subscription-dto.interface";

export class SubscriptionRepository extends BaseRepository<SubscriptionDto> {

    constructor() {
        super(IndexedDBStores.SUBSCRIPTION);
    }

}
