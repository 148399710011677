import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {UserDto} from "../models/dto/user-dto.interface";

export class UserRepository extends BaseRepository<UserDto> {
    private _user: UserDto | null = null;

    constructor() {
        super(IndexedDBStores.USER);
        this.getEntityById(1).then((user) => {
            if (user) {
                this._user = user;
            } else {
                console.warn("No user found in IndexedDB.");
            }
        });
    }

    get user(): UserDto | null {
        return this._user;
    }

    set user(user: UserDto | null) {
        this._user = user;
        if (user) {
            this.updateEntity(user);
        }
    }

    get firstname(): string | null {
        return this._user?.firstname || null;
    }

    set firstname(value: string | null) {
        if (this._user) {
            this._user.firstname = value || '';
            this.updateEntity(this._user);
        }
    }

    get lastname(): string | null {
        return this._user?.lastname || null;
    }

    set lastname(value: string | null) {
        if (this._user) {
            this._user.lastname = value || '';
            this.updateEntity(this._user);
        }
    }

    get age(): number | null {
        return this._user?.age || null;
    }

    set age(value: number | null) {
        if (this._user) {
            this._user.age = value || 0;
            this.updateEntity(this._user);
        }
    }

    get gender(): string | null {
        return this._user?.gender || null;
    }

    set gender(value: string | null) {
        if (this._user) {
            this._user.gender = value || '';
            this.updateEntity(this._user);
        }
    }

    get profession(): string | null {
        return this._user?.profession || null;
    }

    set profession(value: string | null) {
        if (this._user) {
            this._user.profession = value || '';
            this.updateEntity(this._user);
        }
    }

    get profilePictureId(): number | null {
        return this._user?.profile_picture_id || null;
    }

    set profilePictureId(value: number | null) {
        if (this._user) {
            this._user.profile_picture_id = value || 0;
            this.updateEntity(this._user);
        }
    }
}
