import {useEffect, useState} from "react";
import useAreaApi from "../hooks/use-area-api";
import CardChapter from "./card-chapter";
import {Chapter as IChapter, ElementListChapter} from "../types/chapter.interface";
import {useParams} from "react-router-dom";
import EmptyList from "./empty-list";
import ChapterSkeleton from "./chapter-skeleton";


const ListChapters = () => {
    const [chapters, setChapters] = useState<ElementListChapter[]>([])
    const {fetchChapters, loading} = useAreaApi();
    const [isEmpty, setIsEmpty] = useState(false);
    const params = useParams();

    useEffect(() => {
        if (params['subarea']) {
            fetchChapters(params['subarea'])
                .then(res => {
                    res.length && setChapters(res || [])
                    if (!res.length) setIsEmpty(true)
                })
        }
    }, [])

    const updateChapter = (chapter: ElementListChapter) => {
        setChapters((prevState) => {
            return prevState.map(el => {
                if (el.id === chapter.id) return chapter
                else return el
            })
        })
    }

    const renderChapters = () => chapters.map((chapter, index) => <CardChapter updateChapter={updateChapter}
                                                                               key={index} chapter={chapter}/>)

    return (
        <>
            {!isEmpty
                ? (
                    <section
                        className='grid gap-7 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-8 px-[2px]'>
                        {!loading ? (
                            renderChapters()
                        ) : (
                            <>
                                {Array.from({length: 6}).map((i, index) => <ChapterSkeleton key={index}/>)}
                            </>
                        )}
                    </section>
                )
                : <EmptyList content='Brak rozdziałów.'/>
            }
        </>
    )
}

export default ListChapters
