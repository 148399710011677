import React, {createContext, useEffect, useState} from 'react';
import axios from "axios";
import {environment} from "../../../environments/environment";

export const OnlineStatusContext = createContext<boolean | null>(null);

export const OnlineStatusProvider = ({children}: { children: React.ReactNode }) => {
    const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
    // const timeInterval: number = 300000 //5 min
    const timeInterval: number = 1000 * 30 //30 sec
    const axiosInstance = axios.create({
        baseURL: environment.backendUrl,
    })

    async function healthCheck() {
        try {
            const isOnline = await axiosInstance.request<boolean>({
                method: "get",
                url: "/health-check"
            })
            setIsOnline(isOnline.status === 200);
        } catch {
            setIsOnline(false);
        }
    }

    const handleOnline = () => healthCheck();
    const handleOffline = () => setIsOnline(false);

    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        const interval = setInterval(healthCheck, timeInterval);
        healthCheck();

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            clearInterval(interval);
        };
    }, []);

    return (
        <OnlineStatusContext.Provider value={isOnline}>
            {children}
        </OnlineStatusContext.Provider>
    );
};
