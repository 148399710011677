import ApiService from "../../core/services/api.service";
import {CalendarEventRequestBody} from "../types/calendar-event-request-body.interface";
import {TypeCalendarEvent} from "../types/type-event.interface";
import {CalendarController} from "../../core/db/controllers/calendar.controller";
import {GetCalendarEntity} from "../types/get-calendar-entity.interface";

class CalendarApiService extends ApiService {
    private readonly calendarController = new CalendarController();

    constructor() {
        super();
    }

    public async getCalendarEvents(startDate: string, endDate: string): Promise<Array<GetCalendarEntity>> {
        return await this.calendarController.getUserCalendars(startDate, endDate)

        // return await this.get<Array<GetCalendarEntity>>('/calendars/all-user-tasks-events', {
        //     params: {
        //         startDate,
        //         endDate
        //     }
        // })
        //     .then(res => {
        //         res.map(el => IndexedDBService.add(IndexedDBStores.CALENDAR,el))
        //         return res
        //     })
    }

    public async createCalendarEvent(body: CalendarEventRequestBody): Promise<void> {
        const isTask = body.calendarTypeId === TypeCalendarEvent.TASK;
        return await this.calendarController.createTaskOrEventCalendar(body, {
            successMessage: `Dodano ${isTask ? 'zadanie' : 'wydarzenie'} do kalendarza!`,
            errorMessage: `Nie udało się dodać nowego ${isTask ? 'zadania' : 'wydarzenia'}`,
            params: {}
        })
        // return await this.post<void>('/calendars', body, {
        //     successMessage: `Dodano ${isTask ? 'zadanie' : 'wydarzenie'} do kalendarza!`,
        //     errorMessage: `Nie udało się dodać nowego ${isTask ? 'zadania' : 'wydarzenia'}.`
        // })
    }

    public async updateCalendarEvent(eventId: string, body: CalendarEventRequestBody): Promise<void> {
        const isTask = body.calendarTypeId === TypeCalendarEvent.TASK;
        return await this.calendarController.updateTaskOrEventCalendar(eventId, body, {
            successMessage: `Zaktualizowano ${isTask ? 'zadanie' : 'wydarzenie'}!`,
            errorMessage: `Nie udało się zedytować ${isTask ? 'zadania' : 'wydarzenia'}.`,
            params: {}
        });
        // const isTask = body.calendarTypeId === TypeCalendarEvent.TASK;
        // return await this.put<void>(`/calendars/${eventId}`, body, {
        //     successMessage: `Zaktualizowano ${isTask ? 'zadanie' : 'wydarzenie'}!`,
        //     errorMessage: `Nie udało się zedytować ${isTask ? 'zadania' : 'wydarzenia'}.`
        // })
    }

    public async changeCompleted(eventId: string): Promise<void> {
        return await this.calendarController.markAsCompleted(eventId, {
            errorMessage: `Nie udało się zmienić statusu.`,
            params: {},
        });
        // return await this.put<void>(`/calendars/${eventId}/completed`, {},{
        //     errorMessage: `Nie udało się zmienić statusu.`
        // })
    }

    public async deleteCalendarEvent(eventId: string): Promise<void> {
        return await this.calendarController.deleteTaskOrEventCalendar(eventId, {
            errorMessage: `Nie udało się usunąć wpisu kalendarza.`,
            successMessage: "Usunięto wpis z kalendarza.",
            params: {}
        });
        // return await this.delete<void>(`/calendars/${eventId}`,{
        //     errorMessage: `Nie udało się usunąć wpisu kalendarza.`,
        //     successMessage:"Usunięto wpis z kalendarza."
        // })
    }
}

export default new CalendarApiService();
