import {UserSubareaRepository} from "../repositories/user-subarea.repository";
import AuthService from "../../../auth/services/auth.service";

export class UserSubareaService {
    private userSubareaRepository: UserSubareaRepository;

    constructor() {
        this.userSubareaRepository = new UserSubareaRepository();
    }

    /**
     * Adds a new user subarea entity if it does not already exist.
     *
     * @param subareaId - The ID of the subarea to be added for the authenticated user.
     * @throws Will throw an error if the subarea cannot be added due to repository errors or missing authentication.
     */
    public async addEntityIfExists(subareaId: number): Promise<void> {
        try {
            const isExists: boolean = await this.userSubareaRepository.hasSubareaIdForUserId(subareaId);
            const userId: string = AuthService.authenticatedUser?.id!;

            if (!isExists && subareaId && userId) {
                await this.userSubareaRepository.createEntity({
                    subarea_id: subareaId,
                    user_id: Number(userId),
                    completed: 0,
                });
            }
        } catch (err: any) {
            throw new Error("UserSubareaService: Error occurred while adding the entity. Please try again.");
        }
    }
}
