import {UrgentHelpDto} from "../models/dto/urgent-help-dto.interface";
import {UrgentHelpRepository} from "../repositories/urgent-help.repository";

export class UrgentHelpService {
    private urgentHelpRepository: UrgentHelpRepository;

    constructor() {
        this.urgentHelpRepository = new UrgentHelpRepository();
    }

    async getUrgentHelpById(id: number): Promise<UrgentHelpDto> {
        const urgentHelp = await this.urgentHelpRepository.getEntityById(id);
        if (!urgentHelp) {
            throw new Error(`Urgent help data not found`);
        }
        return urgentHelp
    }


    async setUrgentHelp(entity: UrgentHelpDto) {
        await this.urgentHelpRepository.createEntity(entity);
    }
}
