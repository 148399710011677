import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {FileDto} from "../models/dto/file-dto.interface";
import databaseService from "../indexedDB.service";
import { UrgentHelpDto } from "../models/dto/urgent-help-dto.interface";

export class UrgentHelpRepository extends BaseRepository<UrgentHelpDto> {

    constructor() {
        super(IndexedDBStores.URGENT_HELP);
    }

}