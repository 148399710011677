import {ConfigController} from "../models/config-controller.interface";
import NotificationService from "../../../common/services/notification.service";
import {BlockService} from "../services/block.service";
import {BlockDTO as BlocksResponse} from "../../../view-builder/types/get-blocks-response.interface";

export class BlockController {
    private blockService: BlockService;

    constructor() {
        this.blockService = new BlockService();
    }

    /**
     * Fetches blocks for a specific chapter based on the provided configuration.
     *
     * **Expected Parameters in Config:**
     * - `config.params['parentName']` (string)
     * - `config.params['parent']` (string)
     *
     * @param config - Configuration object containing parameters and optional error message.
     * @returns Promise<BlocksResponse[]> - An array of block data for the specified chapter.
     * @throws Error if `chapterName` is missing or an issue occurs during data retrieval.
     */
    async getBlocks(config?: ConfigController): Promise<BlocksResponse[]> {
        try {
            const chapterName: string | undefined = config?.params['parentName'];
            if (!chapterName) {
                throw new Error("The parameter 'parentName' is required.");
            }
            return await this.blockService.getBlocksForChapter(chapterName);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            throw error;
        }
    }

    async updateBlockWithAnswerUser(blockId: number, body: any, config?: ConfigController): Promise<void> {
        try {
            await this.blockService.updateUserBlockAnswers(blockId, body);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            throw error;
        }
    }

    /**
     * Creates pure blocks for a specified parent (chapter or screen).
     *
     * **Expected Parameters in Config:**
     * - config.params['parentName'] (string): Name of the parent (chapter or screen).
     * - config.params['parent'] (string): Type of parent ('CHAPTER' or 'SCREEN').
     *
     * @param config - Configuration object containing parameters and optional error message.
     * @returns Promise<void> - Resolves if the operation succeeds.
     * @throws Error if required parameters are missing or an issue occurs during block creation.
     */
    async createPureBlocks(config?: ConfigController): Promise<void> {
        try {
            const parentName: string | undefined = config?.params['parentName'];
            const parent: "CHAPTER" | "SCREEN" | undefined = config?.params['parent'];

            if (!parentName || !parent) {
                throw new Error("The parameters 'parentName' and 'parent' are required.");
            }

            await this.blockService.createUserBlocksAnswers(parentName, parent);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            throw error;
        }
    }
}
