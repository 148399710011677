import ApiService from "../../core/services/api.service";
import {TypeParent} from "../types/type-parent.interface";
import {BlockDTO} from "../types/get-blocks-response.interface";
import {BlockController} from "../../core/db/controllers/block.controller";

class DynamicViewApiService extends ApiService {
    private readonly blockController = new BlockController();

    constructor() {
        super();
    }

    public async getBlocks(parentName: string, typeParent: TypeParent): Promise<Array<BlockDTO>> {
        return await this.blockController.getBlocks({
            params: {
                parentName: parentName,
                parent: typeParent,
            }
        }).then(res => res || [])
        // return await this.get<Array<BlockDTO>>(`/user-blocks`, {
        //     params: {
        //         parentName:parentName,
        //         parent: typeParent,
        //     },
        //     skipDefault404Handling: true,
        // }).then(res => res || [])
    }

    public async updateBlock(blockId: number, path: string, body: any): Promise<void> {
        return await this.blockController.updateBlockWithAnswerUser(blockId, body)
        // return await this.put<Array<BlockDTO>>(`/user-blocks/${blockId}/${path}`, body, {
        //     skipDefault404Handling: true
        // }).then()
    }

    public async createPureUserBlocks(parentName: string, typeParent: TypeParent): Promise<void> {
        return await this.blockController.createPureBlocks({
            params: {
                parentName,
                parent:typeParent
            }
        })
        // return await this.post<void>(`/user-blocks`, {}, {
        //     skipDefault404Handling: true,
        //     params: {
        //         parentName: parentName,
        //         parent: typeParent
        //     }
        // })
    }
}

export default new DynamicViewApiService();
