import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../core/types/local-storage-keys.interface";
import {useSync} from "../../core/db/hooks/use-sync";
import {useRetryRequest} from "../../core/hooks/use-retry-request";

const StepThree = () => {
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    const {initFirstSyncApp} = useSync();
    const retryRequest = useRetryRequest();

    useEffect(() => {
        const totalTime = 4000;
        const intervalTime = 100;
        const totalSteps = totalTime / intervalTime;
        const increment = 100 / totalSteps;

        let currentStep = 0;

        const updateProgress = () => {
            if (currentStep < totalSteps) {
                currentStep++;
                const randomDelay = Math.floor(Math.random() * 100);
                setTimeout(() => {
                    setProgress(prev => Math.min(prev + increment, 100));
                    updateProgress();
                }, intervalTime + randomDelay);
            }
        };

        updateProgress();
    }, []);

    useEffect(() => {
        if (progress >= 100) {
            LocalStorageService.remove(LocalStorageKeys.LAST_STEP_INTRODUCTION);
            navigate('/main');
        }
    }, [progress, navigate]);

    useEffect(() => {
        retryRequest(initFirstSyncApp, {maxRetries: 5, delay: 2000});
    }, []);

    return (
        <section className='h-full flex flex-col items-center justify-center'>
            <header className='text-center flex flex-col items-center'>
                <img
                    src={require("../../../assets/images/logo_64x64.png")}
                    alt='logo'
                    className='mb-4'
                />
                <h1 className='text-3xl leading-9 font-extrabold theme-dark:text-gray-700'>Pozytywna Zmiana</h1>
            </header>
            <div className='w-full px-4 mt-10'>
                <div className='w-full bg-gray-200 rounded-full h-2.5'>
                    <div
                        className='bg-green-500 h-2.5 rounded-full'
                        style={{width: `${progress}%`, transition: 'width 0.1s ease'}}
                    ></div>
                </div>
                <p className='mt-3 text-center text-2xl leading-7 font-bold theme-dark:text-gray-700'>{Math.floor(progress)}%</p>
            </div>
        </section>
    );
};

export default StepThree;
