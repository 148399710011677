import {IDBPDatabase} from "idb";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";

export const add_entities_from_backend_03 = (db: IDBPDatabase, transaction: IDBTransaction) => {
    console.log("Running migration for version 3");
    const stores: IndexedDBStores[] = Object.values(IndexedDBStores) as IndexedDBStores[];
    for (const storeName of stores) {
        if (!db.objectStoreNames.contains(storeName)) {
            switch (storeName) {
                case "user_area" :
                    db.createObjectStore(storeName, {keyPath: 'area_id', autoIncrement: true});
                    break;
                case "user_subarea" :
                    db.createObjectStore(storeName, {keyPath: 'subarea_id', autoIncrement: true});
                    break;
                case "user_chapter" :
                    db.createObjectStore(storeName, {keyPath: 'chapter_id', autoIncrement: true});
                    break;
                case "user_block" :
                    db.createObjectStore(storeName, {keyPath: 'block_id', autoIncrement: true});
                    break;
                case "user_block_content" :
                    db.createObjectStore(storeName, {keyPath: 'block_content_id', autoIncrement: true});
                    break;
                default:
                    db.createObjectStore(storeName, {keyPath: 'id', autoIncrement: true});
                    break;
            }
            console.log(`Migration 03 - store: ${storeName.toUpperCase()} has been created.`)
        }
    }
}
