import {CalendarDto} from "../models/dto/calendar-dto.interface";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {BaseRepository} from "./base-repository";
import {DateHelper} from "../../utils/dateHelper";

export class CalendarRepository extends BaseRepository<CalendarDto> {

    constructor() {
        super(IndexedDBStores.CALENDAR);
    }

    async deleteCalendarItem(id: string) {
        const item = await this.getEntityById(id);
        let updatedItem: CalendarDto = {
            ...item!,
            deleted: DateHelper.formatDate(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        }
        await this.updateEntity(updatedItem);
    }

}
