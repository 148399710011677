import React, {Suspense} from 'react';
import {AuthProvider} from "./modules/auth/contexts/AuthContext";
import NotificationPrompt from "./modules/common/components/notification/notification-prompt";
import {BrowserRouter as Router} from "react-router-dom";
import AppRoutes from "./app-routes";
import PageLoader from "./modules/common/components/page-loader";
import {InstallPwaProvider} from "./modules/common/contexts/installer-pwa-context";
import {OnlineStatusProvider} from "./modules/core/contexts/online-status-context";
import SyncLoading from "./pages/sync-loading";
import {SyncLoadingProvider} from "./modules/core/contexts/sync-changes-context";

function App() {

    return (
        <OnlineStatusProvider>
            <SyncLoadingProvider>
                <Router>
                    <AuthProvider>
                        <InstallPwaProvider>
                            <Suspense fallback={<PageLoader/>}>
                                <AppRoutes/>
                            </Suspense>
                            <NotificationPrompt/>
                        </InstallPwaProvider>
                    </AuthProvider>
                </Router>
                <SyncLoading/>
            </SyncLoadingProvider>
        </OnlineStatusProvider>
    );
}

export default App;
