import {BaseBlock} from '../types/base-block.interface';
import {DataBlock} from '../types/block.interface';
import {BLOCK_CODE} from '../types/block-codes.interface';
import {createEndBlock} from "../helpers/create-end-block";
import {INextStepBlock} from "../types/blocks/next-step-block.interface";

export class ScenarioService {
    private readonly pages: BaseBlock<DataBlock>[][];
    private readonly initialPageIndex: number;

    constructor(scenario: BaseBlock<DataBlock>[], readonly: boolean = false) {
        const {pages, initialPageIndex} = this.processScenario(scenario, readonly);
        this.pages = pages;
        this.initialPageIndex = initialPageIndex;
    }

    private processScenario(scenario: BaseBlock<DataBlock>[], readonly: boolean) {
        const pagesArray: BaseBlock<DataBlock>[][] = [];
        let currentPageBlocks: BaseBlock<DataBlock>[] = [];
        let initialPage = 0;
        let allPreviousPagesSeen = true;

        scenario.forEach((block, index) => {
            currentPageBlocks.push(block);

            const isLastBlockOnPage =
                block.blockCode === BLOCK_CODE.NEXT_STEP ||
                block.blockCode === BLOCK_CODE.NEXT_STEP_AS_ANSWER ||
                index === scenario.length - 1;

            if (isLastBlockOnPage) {
                pagesArray.push([...currentPageBlocks]);

                const completed = this.isPageCompleted(currentPageBlocks);
                const seenInteractiveBlock = this.hasSeenInteractiveBlock(currentPageBlocks);
                if (completed && allPreviousPagesSeen && seenInteractiveBlock && !readonly) {
                    initialPage = Math.min(initialPage + 1, pagesArray.length);
                }

                if (!seenInteractiveBlock) {
                    allPreviousPagesSeen = false;
                }

                currentPageBlocks = [];
            }
        });

        const lastPageBlocks = pagesArray[pagesArray.length - 1];
        if (lastPageBlocks) {
            const lastBlock = lastPageBlocks[lastPageBlocks.length - 1];
            if (lastBlock.blockCode === BLOCK_CODE.NEXT_STEP) {
                const nextStepBlock = lastBlock as BaseBlock<INextStepBlock>
                nextStepBlock.data.isLastBlockInScenario = true
            } else {
                lastPageBlocks.push(createEndBlock(lastPageBlocks[0], lastPageBlocks.length));
            }
        }

        return {pages: pagesArray, initialPageIndex: Math.min(initialPage, Math.max(0, pagesArray.length - 1))};
    }

    public isPageCompleted(pageBlocks: BaseBlock<DataBlock>[]): boolean {
        const excludedBlocksFromCompletedAlgorithms = [BLOCK_CODE.ADD_TO_CALENDAR_MANUAL]
        return pageBlocks.every(block => {
            if (excludedBlocksFromCompletedAlgorithms.includes(block.blockCode)) return true;
            return (block.blockInteractive && block.isCompleted) || !block.blockInteractive
        });
    }

    private hasSeenInteractiveBlock(pageBlocks: BaseBlock<DataBlock>[]): boolean {
        return pageBlocks.some(block => block.blockInteractive && block.isCompleted);
    }

    public getPages() {
        return this.pages;
    }

    public getInitialPageIndex() {
        return this.initialPageIndex;
    }
}
