import {Route, Routes} from "react-router-dom";
import ListCourses from "../modules/courses/components/list-courses";
import BreadcrumbArea from "../modules/dashboard/components/breadcrumb-area";
import CourseDetailed from "../modules/courses/components/course-detailed";
import {PaymentProvider} from "../modules/core/contexts/payment-context";
import AppWrapper from "../layout/app-wrapper";

const Courses = () => {

    return (
        <AppWrapper className='w-full max-h-full'>
            <main className='py-5 h-full'>
                <BreadcrumbArea skipStart={2} skipEnd={3}/>
                <PaymentProvider>
                    <Routes>
                        <Route index element={<ListCourses/>}/>
                        <Route path='/:area' element={<CourseDetailed/>}/>
                    </Routes>
                </PaymentProvider>
            </main>
        </AppWrapper>
    )
}

export default Courses
