import {useRoutes} from 'react-router-dom';

import LoginView from "./modules/auth/views/login/login";
import RegisterView from "./modules/auth/views/register/register";
import ForgotPasswordView from "./modules/auth/views/forgot-password/forgot-password";
import ResetPasswordView from "./modules/auth/views/reset-password/reset-password";
import TokenHandler from "./modules/auth/components/token-handler";
import AppLandingPage from "./layout/app-landing-page";
import MainLayer from "./layout/app-main";
import PublicRoutes from "./modules/auth/components/PublicRoutes";
import PrivateRoutes from "./modules/auth/components/PrivateRoutes";
import NotFound from "./pages/not-found";
import IntroductionView from "./pages/introuduction";
import AccountActivation from "./modules/auth/components/account-activation";
import {useSync} from "./modules/core/db/hooks/use-sync";
import {useEffect} from "react";
import LocalStorageService from "./modules/common/services/local-storage.service";
import {LocalStorageKeys} from "./modules/core/types/local-storage-keys.interface";
import useAuth from "./modules/auth/hooks/useAuth";

const AppRoutes = () => {
    const {isAuthenticated} = useAuth();
    const {initFirstSyncApp} = useSync();

    useEffect(() => {
        const syncAppInited = LocalStorageService.get(LocalStorageKeys.INIT_SYNC);
        if (!syncAppInited && isAuthenticated) {
            initFirstSyncApp();
        }
    }, [isAuthenticated]);

    return useRoutes(
        [
            {
                path: '/',
                element: <PublicRoutes/>,
                children: [
                    {path: '', element: <AppLandingPage/>},
                    {path: 'login', element: <LoginView/>},
                    {path: 'register', element: <RegisterView/>},
                    {path: 'forgot-password', element: <ForgotPasswordView/>},
                    {path: 'reset-password', element: <ResetPasswordView/>},
                ],
            },
            {
                path: '/main/*',
                element: <PrivateRoutes/>,
                children: [
                    {path: 'introduction', element: <IntroductionView/>},
                    {path: 'activate/:userId/:token', element: <AccountActivation/>},
                    {path: '*', element: <MainLayer/>},
                ],
            },
            {path: '/auth/token/:token', element: <TokenHandler/>},
            {path: '*', element: <NotFound/>},
        ]
    )
};

export default AppRoutes;
