import {TransactionRepository} from "../repositories/transaction.repository";
import {TransactionDto} from "../models/dto/transaction-dto.interface";
import {
    CoursesHistoryTransaction,
    CourseTransaction
} from "../../../dashboard/types/courses-history-transaction.interface";
import {SubscriptionTransaction} from "../../../subscriptions/types/subscriptions-history-transaction";

export class TransactionService {
    private transactionRepository: TransactionRepository;

    constructor() {
        this.transactionRepository = new TransactionRepository();
    }

    /**
     * Method to fetch all subscription transactions.
     * @returns Promise<TransactionDto[]>
     */
    async getAllTransactionSub(): Promise<SubscriptionTransaction[]> {
        return await this.transactionRepository.getAllTransactionSub();
    }

    /**
     * Method to fetch all course transactions.
     * @returns Promise<TransactionDto[]>
     */
    async getAllTransactionCourse(): Promise<CourseTransaction[]> {
        return await this.transactionRepository.getAllTransactionCourse();
    }
}
