import {useState} from "react";
import AreaApiService from "../services/area-api.service";
import {MainArea} from "../types/main-area.interface";
import {AxiosError} from "axios";
import {ElementListSubarea, Subarea} from "../types/sub-area.interface";
import {sortByOrdinalNumber} from "../../common/helpers/sort-by-ordinal-number";
import {Chapter, ElementListChapter} from "../types/chapter.interface";
import {Course} from "../../courses/types/course.interface";
import {CoursesHistoryTransaction, CourseTransaction} from "../types/courses-history-transaction.interface";
import {GetChapterIsCompleted} from "../types/get-chapter-is-completed.interface";

const UseAreaApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const fetchMainAreas = async (): Promise<Array<MainArea>> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getMainAreas()
            .then(res => sortByOrdinalNumber(res))
            .catch((err): [] => {
                return []
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const updateAreasStatus = async (): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.updateStatusAreas()
            .catch(err => {
                setError(err);
                throw err
            })
            .finally((): void => {
                setLoading(false)
            })
    };


    const fetchSubareas = async (areaName: string): Promise<Array<ElementListSubarea>> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getSubAreas(areaName)
            .then(res => sortByOrdinalNumber(res))
            .catch((err): [] => {
                setError(err);
                return []
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const fetchChapters = async (subareaName: string): Promise<Array<ElementListChapter>> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getChapters(subareaName)
            .then(res => sortByOrdinalNumber(res))
            .catch((err): [] => {
                setError(err);
                return []
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const fetchCourses = async (): Promise<Array<MainArea>> => {
        return await fetchMainAreas()
            .then(res => res.filter(area => area.isCourse))
    };

    const fetchCourseByName = async (nameCourse: string): Promise<Course | null> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getCourse(nameCourse)
            .catch((err): null => {
                setError(err);
                return null
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const fetchCoursesTransaction = async (): Promise<CourseTransaction[] | void> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getCoursesHistoryTransaction()
            .catch((err) => {
                setError(err)
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const resetProgressChapter = async (chapterId: number | string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.resetChapter(chapterId)
            .catch((err) => {
                setError(err)
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const changeManualCompletedChapter = async (chapterId: number | string, value: boolean): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.changeManualCompletedChapter(chapterId, value)
            .catch((err) => {
                setError(err)
            })
            .finally((): void => {
                // setLoading(false)
            })
    };

    const fetchChapterIsCompleted = async (chapterName: string): Promise<GetChapterIsCompleted> => {
        setLoading(true);
        setError(null);

        return await AreaApiService
            .getChapterIsCompleted(chapterName)
            .catch(err => {
                setError(err)
                return {} as GetChapterIsCompleted;
            })
            .finally(() => setLoading(false))
    };

    return {
        loading,
        error,
        fetchMainAreas,
        fetchSubareas,
        fetchChapters,
        updateAreasStatus,
        fetchCourses,
        fetchCourseByName,
        fetchCoursesTransaction,
        resetProgressChapter,
        fetchChapterIsCompleted,
        changeManualCompletedChapter
    }
}

export default UseAreaApi
