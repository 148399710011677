import {useState} from "react";
import {AxiosError} from "axios";
import CalendarApiService from "../services/calendar-api.service";
import CalendarService from "../services/calendar.service";
import {CalendarEventRequestBody} from "../types/calendar-event-request-body.interface";
import {GetCalendarEntity} from "../types/get-calendar-entity.interface";

const UseCalendarApi = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const getCalendarData = async (startDate: Date, endDate: Date): Promise<Array<GetCalendarEntity>> => {
        setLoading(true);
        setError(null);
        const startDateAsString = CalendarService.formatDate(startDate, 'YYYY-MM-DD');
        const endDateAsString = CalendarService.formatDate(endDate, 'YYYY-MM-DD');

        return await CalendarApiService.getCalendarEvents(startDateAsString, endDateAsString)
            .catch(err => {
                setError(err)
                return []
            })
            .finally((): void => setLoading(false))
    };

    const createCalendarEvent = async (body: CalendarEventRequestBody): Promise<void> => {
        setLoading(true);
        setError(null);
        const mappedBody: CalendarEventRequestBody = {
            ...body,
            startDate: body.startDate.includes('T')
                ? CalendarService.formatDate(body.startDate, 'YYYY-MM-DDTHH:mm:00')
                : CalendarService.formatDate(body.startDate + 'T00:00:00', 'YYYY-MM-DDTHH:mm:00'),
            endDate: body.endDate.includes('T')
                ? CalendarService.formatDate(body.endDate, 'YYYY-MM-DDTHH:mm:00')
                : CalendarService.formatDate(body.endDate + 'T00:00:00', 'YYYY-MM-DDTHH:mm:00'),
        }

        return await CalendarApiService.createCalendarEvent(mappedBody)
            .then()
            .catch(err => {
                setError(err)
            })
            .finally((): void => setLoading(false))
    };

    const updateCalendarEvent = async (eventId: string, body: CalendarEventRequestBody): Promise<void> => {
        setLoading(true);
        setError(null);
        const mappedBody: CalendarEventRequestBody = {
            ...body,
            startDate: body.startDate.includes('T')
                ? CalendarService.formatDate(body.startDate, 'YYYY-MM-DDTHH:mm:00')
                : CalendarService.formatDate(body.startDate + 'T00:00:00', 'YYYY-MM-DDTHH:mm:00'),
            endDate: body.endDate.includes('T')
                ? CalendarService.formatDate(body.endDate, 'YYYY-MM-DDTHH:mm:00')
                : CalendarService.formatDate(body.endDate + 'T00:00:00', 'YYYY-MM-DDTHH:mm:00'),
        }

        return await CalendarApiService.updateCalendarEvent(eventId, mappedBody)
            .then()
            .catch(err => {
                setError(err)
            })
            .finally((): void => setLoading(false))
    };

    const changeCompletedStatus = async (eventId: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await CalendarApiService.changeCompleted(eventId)
            .then()
            .catch(err => {
                setError(err)
            })
            .finally((): void => setLoading(false))
    };

    const deleteCalendarEvent = async (eventId: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await CalendarApiService.deleteCalendarEvent(eventId)
            .then()
            .catch(err => {
                setError(err)
            })
            .finally((): void => setLoading(false))
    };

    return {
        loading,
        error,
        getCalendarData,
        createCalendarEvent,
        updateCalendarEvent,
        changeCompletedStatus,
        deleteCalendarEvent
    }
}

export default UseCalendarApi
