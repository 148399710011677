import { FileDto } from "../models/dto/file-dto.interface";
import {FileRepository} from "../repositories/file.repository";

export class FileService {
    private fileRepository: FileRepository;

    constructor() {
        this.fileRepository = new FileRepository();
    }

    /**
     * Get file details and content from disk
     * @param fileId - ID of the file
     * @returns Promise<FileDto>
     */
    async getFileDto(fileId: number): Promise<FileDto> {
        const fileDto = await this.fileRepository.getFileById(fileId);
        if (!fileDto) {
            throw new Error(`File with ID ${fileId} not found.`);
        }
        return fileDto
    }
}
