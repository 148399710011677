import {MainArea} from "../../../dashboard/types/main-area.interface";
import {AreaRepository} from "../repositories/area.repository";
import {AreaDto} from "../models/dto/area-dto.interface";
import {FileService} from "./file.service";
import {ImageBase64} from "../../../view-builder/types/image-base-64.interface";
import {ContentCourse, Course} from "../../../courses/types/course.interface";
import {SubareaRepository} from "../repositories/subarea.repository";
import {ChapterRepository} from "../repositories/chapter.repository";
import {UserAreaService} from "./user-area.service";
import {BlockDto} from "../models/dto/block-dto.interface";
import {CompletionService} from "./completion.service";
import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";
import {ChapterDto} from "../models/dto/chapter-dto.interface";
import {UserSubareaDto} from "../models/dto/user-subarea-dto.interface";
import Decimal from "decimal.js";
import {UserAreaDto} from "../models/dto/user-area-dto.interface";
import AuthService from "../../../auth/services/auth.service";
import {UserBlockDto} from "../models/dto/user-block-dto.interface";
import {BLOCK_CODE} from "../../../view-builder";
import {UserChapterRepository} from "../repositories/user-chapter.repository";
import {BlockRepository} from "../repositories/block.repository";
import {UserBlockRepository} from "../repositories/user-block.repository";
import {UserBlockContentRepository} from "../repositories/user-block-content.repository";
import {UserSubareaRepository} from "../repositories/user-subarea.repository";
import {UserAreaRepository} from "../repositories/user-area.repository";
import {SubareaService} from "./subarea.service";
import {ChapterService} from "./chapter.service";

export class AreaService {
    private areaRepository: AreaRepository;
    private subareaRepository: SubareaRepository;
    private userAreaService: UserAreaService;
    private userAreaRepository: UserAreaRepository;
    private chapterRepository: ChapterRepository;
    private fileService: FileService;
    private completionService: CompletionService;

    constructor() {
        this.areaRepository = new AreaRepository();
        this.subareaRepository = new SubareaRepository();
        this.chapterRepository = new ChapterRepository();
        this.userAreaService = new UserAreaService();
        this.fileService = new FileService();
        this.completionService = new CompletionService();
        this.userAreaRepository = new UserAreaRepository();
    }

    async getAreasForUser(): Promise<Array<MainArea>> {
        const areas: AreaDto[] = await this.areaRepository.getAllEntities();
        const response: MainArea[] = []

        for (const area of areas) {
            let image: ImageBase64 | undefined = undefined;
            let userAreaDto = await this.userAreaRepository.getEntityById(area.id)

            if (area.image_file_id) {
                try {
                    const fileDTO = await this.fileService.getFileDto(area.image_file_id);
                    image = {
                        name: fileDTO.name,
                        contentBase64: fileDTO.base64,
                    };
                } catch (error) {
                    console.error('Error loading image for area:', error);
                }
            }

            response.push({
                id: area.id,
                name: area.name,
                isCourse: area.course == 1,
                hasAccess: area.has_access,
                image: image ? {name: image!.name, imageBase64: image!.contentBase64} : {name: "", imageBase64: ""},
                coursePrice: area.price,
                isCompleted: userAreaDto ? userAreaDto.completed_parent : 0,
                isSubscription: area.subscription == 1,
                imageId: area.image_file_id,
                ordinalNumber: area.ordinal_number,
            });
        }

        return response;
    }

    async getCourseWithSubareasAndChaptersByName(courseName: string): Promise<Course> {
        const area = await this.areaRepository.getAreaByName(courseName);

        if (!area) {
            throw new Error(`Course with name '${courseName}' not found.`);
        }

        const subareas = await this.subareaRepository.getSubareasByAreaId(area.id);
        const imageBase64 = area.image_file_id
            ? await this.fileService.getFileDto(area.image_file_id)
            : null;

        const course: Course = {
            id: area.id,
            name: area.name,
            imageBase64: imageBase64?.base64 ?? "",
            price: area.price,
            subareas: [],
        } as unknown as Course

        for (const subarea of subareas) {
            const subareaView: ContentCourse = {
                name: subarea.name,
                chapters: [],
            };

            const chapters = await this.chapterRepository.getChaptersBySubareaId(subarea.id);
            for (const chapter of chapters) {
                subareaView.chapters.push(chapter.name);
            }

            course.subareas.push(subareaView);
        }

        return course;
    }

    public async createUserAreaForm(areaId: number): Promise<void> {
        await this.userAreaService.addEntityIfExists(areaId);
    }

    public async updateCompletionStatus() {
        await this.completionService.updateCompletionStatus();
    }
}
