import {useSync} from "../modules/core/db/hooks/use-sync";
import {useContext, useEffect, useState} from "react";
import {OnlineStatusContext} from "../modules/core/contexts/online-status-context";
import {useAppBackground} from "../modules/settings/hooks/use-app-background";
import {OverlaySidebarMenuProvider} from "../modules/core/contexts/overlay-sidebar-menu-context";
import HeaderLayer from "./app-header";
import {Navigate, Route, Routes} from "react-router-dom";
import MyProfile from "../pages/my-profile";
import NotesView from "../pages/notes";
import Support from "../pages/support";
import Calendar from "../pages/calendar";
import EmailConfirmation from "../modules/auth/components/email-confirmation";
import useAuth from "../modules/auth/hooks/useAuth";
import Courses from "../pages/courses";
import Transactions from "../pages/transactions";
import Subscriptions from "../pages/subscriptions";
import {InstructionsInstallPwa} from "../modules/common/components/instructions-install-pwa";
import Zencal from "../modules/core/zencal/zencal";
import zencalService from "../modules/core/zencal/zencal-service";
import SideBarLayer from "./app-sidebar";
import withIntroductionGuard from "../modules/core/hoc/withIntroductionGuard";
import LocalStorageService from "../modules/common/services/local-storage.service";
import {LocalStorageKeys} from "../modules/core/types/local-storage-keys.interface";
import Dashboard from "../pages/dashboard";


const MainLayer = () => {
    const {currentBackground} = useAppBackground();
    const {hasEmailConfirmed} = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenu = (value: boolean) => {
        setIsMenuOpen(value);
    };

    const {synchronizeApp} = useSync();

    const isOnline = useContext(OnlineStatusContext);
    const [syncIntervalId, setSyncIntervalId] = useState<NodeJS.Timeout | null>(null);
    // const timeInterval: number = 300000; // 5 min
    const timeInterval: number = 1000 * 20; // 1 min
    // const timeInterval: number = 1000; // 1 s

    const startSyncInterval = () => {
        if (!syncIntervalId) {
            const lastSyncDate = LocalStorageService.get(LocalStorageKeys.LAST_SYNC);
            const now = new Date().getTime();

            let timeToNextSync = timeInterval;

            if (lastSyncDate) {
                const timeSinceLastSync = now - lastSyncDate;
                timeToNextSync = timeInterval - timeSinceLastSync;

                if (timeToNextSync < 0) {
                    timeToNextSync = 0;
                }
            }

            console.log(`Next sync in: ${timeToNextSync}ms`);

            synchronizeApp();
            LocalStorageService.save(LocalStorageKeys.LAST_SYNC, new Date().getTime());

            const timeoutId = setTimeout(() => {
                const intervalId = setInterval(() => {
                    synchronizeApp();
                    LocalStorageService.save(LocalStorageKeys.LAST_SYNC, new Date().getTime());
                }, timeInterval);

                setSyncIntervalId(intervalId);
            }, timeToNextSync);

            setSyncIntervalId(timeoutId as unknown as NodeJS.Timeout);
        }
    };


    const clearSyncInterval = () => {
        if (syncIntervalId) {
            console.log("Clearing sync interval...");
            clearInterval(syncIntervalId);
            setSyncIntervalId(null);
        }
    };

    useEffect(() => {
        isOnline ? startSyncInterval() : clearSyncInterval();
        return () => clearSyncInterval();
    }, [isOnline]);

    const [showZencal, setShowZencal] = useState(false);

    const isZencal = () => {
        zencalService.getShowZencal()
            .then(res => setShowZencal(res.isAvailable))
            .catch(e => setShowZencal(false));
    }

    useEffect(() => {
        isZencal();
    }, [])

    return (
        <OverlaySidebarMenuProvider>
            <div className={'w-full bg-gray-50 max-h-svh h-svh flex flex-col ' + currentBackground.bgBackdropClasses}>
                <InstructionsInstallPwa/>
                <HeaderLayer handleOpenMenu={handleMenu} showZencal={showZencal}/>
                <Routes>
                    <Route path="/*" element={<Dashboard/>}/>
                    <Route path="/courses/*" element={<Courses/>}/>
                    <Route path="/subscriptions" element={<Subscriptions/>}/>
                    <Route path="profile" element={<MyProfile/>}/>
                    <Route path="history-transactions" element={<Transactions/>}/>
                    <Route path="notes" element={<NotesView/>}/>
                    <Route path="support" element={<Support/>}/>
                    <Route path="calendar" element={<Calendar/>}/>
                    {(showZencal) && <Route path="/consultations" element={<Zencal showZencal={showZencal}/>}/>}
                    <Route path="*" element={<Navigate to="/not-found"/>}/>
                </Routes>
                {!hasEmailConfirmed() && <EmailConfirmation/>}
                <SideBarLayer isMenuOpen={isMenuOpen}/>
            </div>
        </OverlaySidebarMenuProvider>
    );
};

export default withIntroductionGuard(MainLayer);
