import SubscriptionList from "../modules/subscriptions/components/subscription-list";
import {PaymentProvider} from "../modules/core/contexts/payment-context";
import AppWrapper from "../layout/app-wrapper";

const Subscriptions = () => {
    return (
        <AppWrapper className='w-full max-h-full'>
            <main className='py-5 h-full'>
                <PaymentProvider>
                    <SubscriptionList/>
                </PaymentProvider>
            </main>
        </AppWrapper>
    )
}

export default Subscriptions
