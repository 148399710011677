import {CalendarEvent} from "../types/calendar-event.interface";
import {Task} from "../types/task.interface";
import CalendarService from "./calendar.service";
import {Week} from "../types/week.interface";
import {Day} from "../types/day.interface";
import {TypeCalendarEvent} from "../types/type-event.interface";
import {GetCalendarEntity} from "../types/get-calendar-entity.interface";

class ScheduleService {
    getMonthStartAndEndDates(date: Date): [Date, Date] {
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return [start, end];
    }

    generateEmptySchedule(startDate: Date): Week[] {
        const year = startDate.getFullYear();
        const month = startDate.getMonth();

        const daysInMonth = CalendarService.getDaysInMonth(year, month);
        const schedule: Week[] = [];
        let currentWeek: Week = {label: '', days: []};

        for (let i = 1; i <= daysInMonth; i++) {
            const date = new Date(year, month, i);
            const dayOfWeek = date.getDay();

            const day: Day = {
                weekday: CalendarService.getWeekdayByDay((dayOfWeek === 0) ? 6 : dayOfWeek - 1),
                date: i,
                tasks: [],
            };

            currentWeek.days.push(day);

            if (dayOfWeek === 0 || i === daysInMonth) {
                const firstDayLabel = currentWeek.days[0]?.date;

                if (firstDayLabel !== undefined && firstDayLabel === i) {
                    currentWeek.label = `${firstDayLabel} ${CalendarService.getMonthName(month)}`;
                } else if (firstDayLabel !== undefined) {
                    currentWeek.label = `${firstDayLabel}-${i} ${CalendarService.getMonthName(month)}`;
                }

                schedule.push(currentWeek);
                currentWeek = {label: '', days: []};
            }
        }

        return schedule;
    }

    sortTasksByTime(currentDate: Date) {
        return (taskA: Task, taskB: Task): number => {
            const parseTime = (time: string): [number, number] | null => {
                if (!time || !time.includes(' - ')) return null;
                const [startTime] = time.split(' - ');
                const [hours, minutes] = startTime.split(':').map(Number);
                return [hours, minutes];
            };

            const startTimeA = parseTime(taskA.time);
            const startTimeB = parseTime(taskB.time);

            if (!startTimeA && !startTimeB) return 0;
            if (!startTimeA) return 1;
            if (!startTimeB) return -1;

            const dateA = new Date(currentDate);
            const dateB = new Date(currentDate);

            dateA.setHours(startTimeA[0], startTimeA[1]);
            dateB.setHours(startTimeB[0], startTimeB[1]);

            return dateA.getTime() - dateB.getTime();
        };
    }


    getTasksForDay(day: number, apiData: GetCalendarEntity[], currentDate: Date): Task[] {
        return apiData
            .filter(event => this.isEventInDayRange(event.startDate, event.endDate, day, currentDate))
            .map(event => {
                const start = new Date(event.startDate).setHours(0, 0, 0, 0);
                const end = new Date(event.endDate).setHours(23, 59, 59, 999);
                const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).getTime();
                const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
                const currentDayIndex = Math.ceil((currentDay - start) / (1000 * 60 * 60 * 24)) + 1;

                const titleWithDayIndex = totalDays > 1
                    ? `(${currentDayIndex} z ${totalDays}) ${event.title}`
                    : event.title;

                const isFullyDay = event.startDate.includes('T00:00:00') && event.endDate.includes('T00:00:00') && (totalDays <= 1);
                const isTask = event.calendarTypeName === "Zadanie";
                const isEvent = event.calendarTypeName === "Wydarzenie";
                const isLastDay = isTask && (totalDays === 1 || currentDayIndex === totalDays);

                let displayEventTime: string = "";

                if (isEvent && totalDays > 1) {
                    if (currentDayIndex === 1) {
                        displayEventTime = `Rozpoczęcie o ${this.formatEventTime(event.startDate)}`;
                    } else if (currentDayIndex === totalDays) {
                        displayEventTime = `Koniec o ${this.formatEventTime(event.endDate)}`;
                    }
                } else if (isEvent && totalDays < 1) {
                    displayEventTime = this.formatEventStartAndEndTime(event.startDate, event.endDate);
                }

                return {
                    title: event.title,
                    displayTitle: titleWithDayIndex,
                    time: displayEventTime,
                    isFullDay: isFullyDay,
                    completed: event.completed,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    reminders: event.reminders,
                    id: event.id,
                    calendarTypeName: event.calendarTypeName,
                    calendarTypeId: isTask ? TypeCalendarEvent.TASK : TypeCalendarEvent.EVENT,
                    isLastDay: isLastDay
                } as unknown as Task;
            });
    }

    private isEventInDayRange(startDate: string, endDate: string, day: number, currentDate: Date): boolean {
        const start = new Date(startDate).setHours(0, 0, 0, 0);
        const end = new Date(endDate).setHours(23, 59, 59, 999);
        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).getTime();

        return currentDay >= start && currentDay <= end;
    }

    private formatEventStartAndEndTime(startDateString: string, endDateString: string): string {
        const startDate: Date = new Date(startDateString);
        const endDate: Date = new Date(endDateString);
        const startTime: string = `${startDate.getHours()}:${String(startDate.getMinutes()).padStart(2, '0')}`;
        const endTime: string = `${endDate.getHours()}:${String(endDate.getMinutes()).padStart(2, '0')}`
        return `${startTime} - ${endTime}`;
    }

    private formatEventTime(dateString: string): string {
        const date: Date = new Date(dateString);
        return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
    }
}

export default new ScheduleService();
