import {SubscriptionService} from "../services/subscription.service";
import {SubscriptionDto} from "../models/dto/subscription-dto.interface";

export class SubscriptionController {
    private subscriptionService: SubscriptionService;

    constructor() {
        this.subscriptionService = new SubscriptionService();
    }

    /**
     * Method to fetch all subscriptions and return them as an array of entities.
     * @returns Promise<SubscriptionDto[]>
     */
    async getAllUserSubscriptions(): Promise<SubscriptionDto[]> {
        return await this.subscriptionService.getAllSubscriptions();
    }
}
