import { UrgentHelpController } from "../../core/db/controllers/urgent-help.controller";
import ApiService from "../../core/services/api.service";
import {UrgentHelp} from "../types/urgent-help";

class SupportApiService extends ApiService {
    
    private urgentHelpController: UrgentHelpController;

    constructor() {
        super();
        this.urgentHelpController = new UrgentHelpController;
    }


    public async getUrgentHelpDetails(): Promise<UrgentHelp> {
        return await this.urgentHelpController.getUrgentHelp();
        // return await this.get<UrgentHelp>('/urgent-help', {
        //     headers:{
        //         "Content-Type": "text/plain"
        //     }
        // })
        //     .then(res => res)
    }
}

export default new SupportApiService();
