import {CalendarRepository} from "../repositories/calendar.repository";
import {CalendarDto} from "../models/dto/calendar-dto.interface";
import {
    CalendarEventRequestBody as CalendarBodyRequest
} from "../../../calendar/types/calendar-event-request-body.interface";
import AuthService from "../../../auth/services/auth.service";
import {BinaryBoolean} from "../models/types/binary_boolean.type";
import {TypeCalendarEvent} from "../../../calendar/types/type-event.interface";
import {DateHelper} from "../../utils/dateHelper";
import {GetCalendarEntity} from "../../../calendar/types/get-calendar-entity.interface";
import {v4 as uuidv4} from 'uuid';

export class CalendarService {
    private calendarRepository: CalendarRepository;

    constructor() {
        this.calendarRepository = new CalendarRepository();
    }

    // async getAllCalendars(): Promise<IndexeddbData<CalendarDto[]>> {
    //     return this.calendarRepository.getAllEntities();
    // }

    async getAllCalendars(startDate: string, endDate: string): Promise<Array<GetCalendarEntity>> {
        try {
            const response: CalendarDto[] = await this.calendarRepository.getAllEntities();
            const filteredCalendars: CalendarDto[] = response.filter(calendar => {
                const start = new Date(DateHelper.formatDate(new Date((calendar as any).startDate), "YYYY-MM-DD")).getTime();
                const end = new Date(DateHelper.formatDate(new Date((calendar as any).endDate), "YYYY-MM-DD")).getTime();
                const filterStart = new Date(startDate).getTime();
                const filterEnd = new Date(endDate).getTime();
                return !(start >= filterStart && start <= filterEnd) || (end >= filterStart && end <= filterEnd);
            });
            return filteredCalendars
                .filter(el => !el?.deleted)
                .map(calendar => ({
                id: calendar.id,
                calendarTypeName: calendar.calendar_type_id === TypeCalendarEvent.TASK ? "Zadanie" : "Wydarzenie",
                title: calendar.title,
                startDate: calendar.start_date,
                endDate: calendar.end_date,
                completed: calendar.completed === 1,
                // created: calendar.created,
                // edited: calendar.edited,
                deleted: calendar?.deleted || undefined,
                reminders: calendar.reminders.map(el => el.date),
            }))
        } catch (error: any) {
            console.error('Error:', error);
            throw error;
        }
    }


    async createCalendar(data: CalendarBodyRequest): Promise<void> {
        if (!data.title) {
            throw new Error('Title is required');
        }
        const userId: string = AuthService.authenticatedUser?.id!

        const completedValue: BinaryBoolean | null = data.calendarTypeId === TypeCalendarEvent.TASK
            ? 0
            : null

        const calendarDto: Omit<CalendarDto, ''> = {
            id: uuidv4(),
            user_id: Number(userId),
            completed: completedValue,
            deleted: null,
            start_date: data.startDate,
            end_date: data.endDate,
            // edited: DateHelper.formatDate(new Date(), "YYYY-MM-DDT00:00:00"),
            // created: DateHelper.formatDate(new Date(), "YYYY-MM-DDT00:00:00"),
            calendar_type_id: data.calendarTypeId,
            title: data.title,
            completion_date: null,
            reminders: data.reminders
        }
        await this.calendarRepository.createEntity(calendarDto);
    }

    async updateCalendar(id: string, calendar: CalendarBodyRequest): Promise<void> {
        const existingCalendar = await this.calendarRepository.getEntityById(id);
        if (!existingCalendar) {
            throw new Error(`Calendar with ID ${id} not found.`);
        }

        const calendarDto: CalendarDto = {
            ...existingCalendar,
            start_date: calendar.startDate,
            end_date: calendar.endDate,
            // edited: DateHelper.formatDate(new Date(), "YYYY-MM-DDT00:00:00"),
            calendar_type_id: calendar.calendarTypeId,
            title: calendar.title,
            reminders: calendar.reminders,
            completed: null,
        }
        return this.calendarRepository.updateEntity(calendarDto);
    }

    async deleteCalendar(id: string): Promise<void> {
        return this.calendarRepository.deleteCalendarItem(id);
    }

    async markAsCompleted(id: string): Promise<void> {
        const existingCalendar = await this.calendarRepository.getEntityById(id);
        if (!existingCalendar) {
            throw new Error(`Calendar with ID ${id} not found.`);
        }

        const calendarDto: CalendarDto = {
            ...existingCalendar,
            completed: 1,
            completion_date: DateHelper.formatDate(new Date(), "YYYY-MM-DDT00:00:00"),
        }
        await this.calendarRepository.updateEntity(calendarDto);
    }
}
