import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";
import {ChapterDto} from "../models/dto/chapter-dto.interface";
import {UserSubareaDto} from "../models/dto/user-subarea-dto.interface";
import Decimal from "decimal.js";
import {UserAreaDto} from "../models/dto/user-area-dto.interface";
import AuthService from "../../../auth/services/auth.service";
import {UserBlockDto} from "../models/dto/user-block-dto.interface";
import {BLOCK_CODE} from "../../../view-builder";
import {UserChapterRepository} from "../repositories/user-chapter.repository";
import {BlockRepository} from "../repositories/block.repository";
import {UserBlockRepository} from "../repositories/user-block.repository";
import {UserBlockContentRepository} from "../repositories/user-block-content.repository";
import {UserSubareaRepository} from "../repositories/user-subarea.repository";
import {UserAreaRepository} from "../repositories/user-area.repository";
import {SubareaService} from "./subarea.service";
import {ChapterService} from "./chapter.service";
import {ChapterRepository} from "../repositories/chapter.repository";
import {SubareaRepository} from "../repositories/subarea.repository";
import {AreaRepository} from "../repositories/area.repository";
import {BlockDto} from "../models/dto/block-dto.interface";
import {SubareaDto} from "../models/dto/subarea-dto.interface";
import {AreaDto} from "../models/dto/area-dto.interface";

export class CompletionService {

    private areaRepository: AreaRepository;
    private subareaRepository: SubareaRepository;
    private userBlockContentRepository: UserBlockContentRepository;

    private chapterRepository: ChapterRepository;
    private userChapterRepository: UserChapterRepository;
    private blockRepository: BlockRepository;
    private userBlockRepository: UserBlockRepository;
    private userSubareaRepository: UserSubareaRepository;
    private userAreaRepository: UserAreaRepository;

    private subareaService: SubareaService;
    private chapterService: ChapterService;

    constructor() {
        this.areaRepository = new AreaRepository();
        this.subareaRepository = new SubareaRepository();
        this.userBlockContentRepository = new UserBlockContentRepository();

        this.chapterRepository = new ChapterRepository();
        this.userChapterRepository = new UserChapterRepository();
        this.blockRepository = new BlockRepository();
        this.userBlockRepository = new UserBlockRepository();
        this.userSubareaRepository = new UserSubareaRepository();
        this.userAreaRepository = new UserAreaRepository();

        this.subareaService = new SubareaService();
        this.chapterService = new ChapterService();
    }

    async updateCompletionStatus(): Promise<void> {
        await this.createUserPureAreasSubareasChapters();

        const areas = await this.areaRepository.getAllEntities();

        for (const area of areas) {
            const userArea = await this.userAreaRepository.getEntityById(area.id).then(res => res!);
            const subareas = await this.subareaRepository.getSubareasByAreaId(area.id);

            let totalChaptersInArea = 0;
            let completedChaptersInArea = 0;

            for (const subarea of subareas) {
                const chapters = await this.chapterRepository.getChaptersBySubareaId(subarea.id);
                const userSubarea = await this.userSubareaRepository.getEntityById(subarea.id).then(res => res!);

                const {
                    subareaCompleted,
                    completedChapters
                } = await this.updateSubareaCompletionStatus(subarea, chapters);

                await this.updateUserSubareaCompletion(userSubarea, subareaCompleted);

                totalChaptersInArea += chapters.length;
                completedChaptersInArea += completedChapters;
            }

            await this.updateAreaCompletionPercentage(userArea, completedChaptersInArea, totalChaptersInArea);
        }
    }

    // Aktualizacja statusu ukończenia podobszaru
    private async updateSubareaCompletionStatus(subarea: SubareaDto, chapters: ChapterDto[]): Promise<{
        subareaCompleted: boolean,
        completedChapters: number
    }> {
        let subareaCompleted = true;
        let completedChapters = 0;

        for (const chapter of chapters) {
            const userChapter = await this.userChapterRepository.getEntityById(chapter.id).then(res => res!);

            const chapterCompleted = await this.updateChapterCompletionStatus(chapter, userChapter);

            if (!chapterCompleted) {
                subareaCompleted = false;
            } else {
                completedChapters++;
            }
        }

        return {subareaCompleted, completedChapters};
    }

    private async updateAreaCompletionPercentage(userArea: UserAreaDto, completedChapters: number, totalChapters: number): Promise<void> {
        const completionPercentage = totalChapters > 0
            ? new Decimal(completedChapters)
                .div(totalChapters)
                .mul(100)
                .toDecimalPlaces(0)
                .toNumber()
            : 0;

        const updatedUserArea: UserAreaDto = {
            ...userArea,
            completed_parent: completionPercentage,
        };
        await this.userAreaRepository.updateEntity(updatedUserArea);
    }

    private async updateUserSubareaCompletion(userSubarea: UserSubareaDto, subareaCompleted: boolean): Promise<void> {
        const updatedUserSubarea: UserSubareaDto = {
            ...userSubarea,
            completed: subareaCompleted ? 1 : 0,
        };
        await this.userSubareaRepository.updateEntity(updatedUserSubarea);
    }

    // Aktualizacja statusu ukończenia rozdziału
    private async updateChapterCompletionStatus(chapter: ChapterDto, userChapter: UserChapterDto): Promise<boolean> {
        const blocks = await this.blockRepository.getInteractiveBlocksForChapter(chapter.id);

        if (!blocks || blocks.length === 0) {
            return userChapter.manual_completed == 1;
        }

        const chapterCompleted = await Promise.all(
            blocks.map(async (b) => {
                const userBlock = await this.userBlockRepository.getEntityByBlockId(b.id);
                if (!userBlock) {
                    const userBlockContents = await this.userBlockContentRepository.getAllEntitiesByBlockId(b.id);
                    if (b.block_code == 'ADD_TO_CALENDAR_MANUAL') {
                        return true;
                    }

                    if (!userBlockContents || userBlockContents.length === 0) {
                        return false;
                    }

                    if (b.block_code === 'PHRASE_COMPLETION') {
                        return userBlockContents.every((content) => content.sentence !== null);
                    } else if (['PHRASE_PYRAMID'].includes(b.block_code)) {
                        return true;
                    } else {
                        return userBlockContents.some((content) => content.selected);
                    }
                }
                return this.isBlockCompleted(userBlock, b);
            })
        ).then((results) => results.every((result) => result));

        const chapterIsCompleted = userChapter.manual_completed ? true : chapterCompleted;

        const updatedUserChapter: UserChapterDto = {
            ...userChapter,
            completed: chapterIsCompleted ? 1 : 0,
        };
        await this.userChapterRepository.updateEntity(updatedUserChapter);

        return chapterIsCompleted;
    }

    private async createUserPureAreasSubareasChapters(): Promise<void> {
        const areas = await this.areaRepository.getAllEntities();
        const userId = AuthService.authenticatedUser?.id!

        for (const area of areas) {
            let userArea = await this.userAreaRepository.getEntityById(area.id);
            if (!userArea) await this.userAreaRepository.createEntity({
                area_id: area.id,
                user_id: Number(userId),
                completed_parent: 0,
            });

            const subareas = await this.subareaService.getSubareasForUser(area.name);
            for (const subarea of subareas) {
                let userSubarea = await this.userSubareaRepository.getEntityById(subarea.id);
                if (!userSubarea) await this.subareaService.createUserSubareaForm(subarea.id)

                const chapters = await this.chapterService.getChaptersForUser(subarea.name);
                for (const chapter of chapters) {
                    let userChapter = await this.userChapterRepository.getEntityById(chapter.id);
                    if (!userChapter) await this.chapterService.createUserChapterForm(chapter.id)
                }
            }
        }
    }

    private async isBlockCompleted(userBlock: UserBlockDto, block: BlockDto): Promise<boolean> {
        const blockCode: BLOCK_CODE = block.block_code
        if (blockCode === "NEXT_STEP") {
            if (userBlock.completed === 1) {
                return true;
            }
        }

        return (
            userBlock.short_answer !== null ||
            userBlock.long_answer !== null ||
            userBlock.audio_path !== null ||
            userBlock.video_path !== null ||
            userBlock.poll_answer !== null
        );
    }

    // async updateCompletionStatus(): Promise<void> {
    //     await this.createUserPureAreasSubareasChapters();
    //
    //     const userChapters: UserChapterDto[] = await this.userChapterRepository.getAllEntities();
    //     for (const userChapter of userChapters) {
    //         const chapter = await this.chapterRepository.getEntityById(userChapter.chapter_id);
    //         if (chapter) {
    //             await this.updateUserCompletionStatus(chapter);
    //         }
    //     }
    // }
    //
    // private async updateUserCompletionStatus(chapter: ChapterDto): Promise<void> {
    //     const subareaId = chapter.subarea_id;
    //     const subarea = await this.subareaRepository.getEntityById(subareaId);
    //     const areaId = subarea?.area_id!
    //
    //     const blocks = await this.blockRepository.getInteractiveBlocksForChapter(chapter.id);
    //     if (!blocks || blocks.length === 0) {
    //         return;
    //     }
    //
    //     const chapterCompleted = await Promise.all(
    //         blocks.map(async (b) => {
    //             const userBlock = await this.userBlockRepository.getEntityByBlockId(b.id);
    //             if (!userBlock) {
    //                 const userBlockContents = await this.userBlockContentRepository.getAllEntitiesByBlockId(b.id);
    //                 if (!userBlockContents || userBlockContents.length === 0) {
    //                     return false;
    //                 }
    //
    //                 if (b.block_code == "PHRASE_COMPLETION") {
    //                     return userBlockContents.every((content) => content.sentence !== null);
    //                 } else if (b.block_code == "PHRASE_PYRAMID" || b.block_code == "ADD_TO_CALENDAR_MANUAL") {
    //                     return true;
    //                 } else {
    //                     return userBlockContents.some((content) => content.selected);
    //                 }
    //             }
    //             return await this.isBlockCompleted(userBlock, b);
    //         })
    //     ).then(results => results.every(result => result));
    //
    //     const userChapter: UserChapterDto = await this.userChapterRepository.getEntityById(chapter.id) as UserChapterDto
    //
    //     if (userChapter.manual_completed) {
    //         const updatedUserChapter: UserChapterDto = {
    //             ...userChapter,
    //             completed: 1,
    //         }
    //         await this.userChapterRepository.updateEntity(updatedUserChapter)
    //     } else {
    //         const updatedUserChapter: UserChapterDto = {
    //             ...userChapter,
    //             completed: chapterCompleted ? 1 : 0,
    //         }
    //         await this.userChapterRepository.updateEntity(updatedUserChapter)
    //     }
    //
    //     const userChapters = await this.subareaRepository.getAllUserChaptersBySubareaId(subareaId);
    //     const subareaCompleted = !!userChapters?.every((chapter) => chapter.completed);
    //
    //     let userSubarea: UserSubareaDto | undefined = await this.userSubareaRepository.getEntityById(subareaId);
    //     if (userSubarea){
    //         const updatedUserSubarea: UserSubareaDto = {
    //             ...userSubarea!,
    //             completed: subareaCompleted ? 1 : 0,
    //         }
    //         await this.userSubareaRepository.updateEntity(updatedUserSubarea)
    //     }
    //
    //     const userChaptersForArea = await this.areaRepository.getUserChaptersByAreaId(areaId);
    //     const completedChapters = userChaptersForArea.filter((chapter) => chapter.completed).length;
    //     const totalChapters = userChaptersForArea.length;
    //
    //     const completionPercentage = new Decimal(completedChapters)
    //         .div(totalChapters)
    //         .mul(100)
    //         .toDecimalPlaces(2)
    //         .toNumber();
    //
    //     let userArea = await this.userAreaRepository.getEntityById(areaId);
    //
    //     if (userArea){
    //         const updatedUserArea: UserAreaDto = {
    //             ...userArea!,
    //             completed_parent: completionPercentage || 0
    //         }
    //         await this.userAreaRepository.updateEntity(updatedUserArea);
    //     }
    // }
}
