import React, {useRef} from 'react';
import {MainArea} from "../types/main-area.interface";
import {LockClosedIcon, PhotoIcon} from "@heroicons/react/24/outline";
import ProgressRing from "./progress-ring";
import useElementStates from "../../core/hooks/use-element-states";
import {useNavigate} from "react-router-dom";
import {PrefixBase64} from "../../core/constants/prefixBase64";
import StatusLabel from "../../common/components/status-label";
import {CheckIcon} from "@heroicons/react/24/solid";

const Area: React.FC<MainArea> = (props) => {
    const refArea = useRef<HTMLElement>(null);
    const {isPressed, isHovered} = useElementStates({ref: refArea});
    const navigate = useNavigate();

    const goToSubareas = () => navigate(`/main/${encodeURIComponent(props.name)}`);
    const goToDetailsCourse = () => navigate(`/main/courses/${encodeURIComponent(props.name)}`);
    const goToDetailsSubscription = () => navigate(`/main/subscriptions`, {
        state: {areaId: props.id}
    });

    const hasImage = (): boolean => !!props.image?.imageBase64;
    const isCourseWithoutAccess = (): boolean => props.isCourse && !props.hasAccess;
    const hasCourseWithAccess = (): boolean => props.isCourse && props.hasAccess;
    const isSubscriptionWithoutAccess = (): boolean => props.isSubscription && !props.hasAccess;
    const hasSubscriptionWithAccess = (): boolean => props.isSubscription && props.hasAccess;

    const shouldRenderProgressRing = (): boolean => (!props.isCourse && !props.isSubscription) || hasCourseWithAccess() || hasSubscriptionWithAccess();

    const handleClick = () => {
        if (isCourseWithoutAccess()) {
            goToDetailsCourse();
        } else if (isSubscriptionWithoutAccess()) {
            goToDetailsSubscription();
        } else {
            goToSubareas();
        }
    };

    return (
        <article
            onClick={handleClick}
            ref={refArea}
            className={`rounded-lg flex sm:flex-col w-full sm:rounded-lg sm:overflow-hidden bg-white max-h-[110px] sm:max-h-[400px]
            overflow-hidden shadow-lg ring-black ring-opacity-5 ring-1
            transform transition-transform duration-300 cursor-pointer border-primary_600 box-border
            ${isHovered ? 'border-[1px]' : ''} cursor-pointer
            ${isPressed ? 'border-2' : ''} cursor-pointer`}
        >
            <div
                className='hidden xs:block relative sm:w-full rounded-l-lg sm:rounded-none sm:h-60 bg-gray-200 w-28 min-w-28'>
                {hasImage() ? (
                    <img
                        className='!w-full h-full object-cover'
                        alt={props.name}
                        src={PrefixBase64 + props.image?.imageBase64}
                    />
                ) : (
                    <PhotoIcon className='w-full h-full text-gray-500'/>
                )}
            </div>
            <div className='flex flex-grow overflow-hidden'>
                <header
                    className='flex flex-col rounded-l-lg overflow-hidden flex-grow m-4 h-fit sm:max-h-[86px] gap-1 '>
                    <h6 className='text-gray-900 font-semibold text-md truncate'>
                        {props.name}
                    </h6>
                    <span className='flex justify-between flex-col gap-x-1 gap-y-2'>
                    {isCourseWithoutAccess() ? (
                        <>
                            <StatusLabel
                                text='Kurs'
                                icon={<LockClosedIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit h-fit py-[1px] font-medium leading-4 text-xs bg-white text-green-600 border border-green-500'
                            />
                            <div className="flex justify-end flex-grow">
                                <StatusLabel
                                    text='Wykup dostęp'
                                    classNameIcon='w-3 h-3'
                                    className='w-fit h-fit px-4 py-[7px] font-medium leading-4 text-xs bg-white text-green-700 shadow-sm !bg-primary_100'
                                />
                            </div>
                        </>
                    ) : isSubscriptionWithoutAccess() ? (
                        <>
                            <StatusLabel
                                text='Subskrypcja'
                                icon={<LockClosedIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit h-fit py-[1px] font-medium leading-4 text-xs bg-white text-yellow-800 border border-yellow-400'
                            />
                            <div className="flex justify-end flex-grow">
                                <StatusLabel
                                    text='Wykup dostęp'
                                    classNameIcon='w-3 h-3'
                                    className='w-fit h-fit px-4 py-[7px] font-medium leading-4 text-xs bg-white text-green-700 shadow-sm !bg-primary_100'
                                />
                            </div>
                        </>
                    ) : (
                        props.isCourse ? (
                            <StatusLabel
                                text='Twój kurs'
                                icon={<CheckIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit h-fit py-[1px] font-medium leading-4 text-xs bg-white text-green-600 border border-green-500'
                            />
                        ) : props.isSubscription && (
                            <StatusLabel
                                text='Subskrypcja'
                                icon={<CheckIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit h-fit py-[1px] font-medium leading-4 text-xs bg-white text-green-600 border border-green-500'
                            />
                        )
                    )}
                </span>
                </header>
                {shouldRenderProgressRing() && (
                    <aside className='flex items-center justify-end min-w-max h-full'>
                        <ProgressRing radius={36} progress={props.isCompleted} stroke={6}
                                      isCompleted={props.isCompleted === 100}/>
                    </aside>
                )}
            </div>
        </article>
    );
};

export default Area;
