import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {UserBlockDto} from "../models/dto/user-block-dto.interface";
import {BlockDto} from "../models/dto/block-dto.interface";
import databaseService from "../indexedDB.service";

export class UserBlockRepository extends BaseRepository<UserBlockDto> {
    constructor() {
        super(IndexedDBStores.USER_BLOCK);
    }

    /**
     * Check if a user block exists for a specific block ID.
     * @param blockId - Block ID
     * @returns Promise<boolean>
     */
    async isExists(blockId: number): Promise<boolean> {
        const userBlockDto: UserBlockDto | undefined = await this.getEntityByBlockId(blockId);
        return !!userBlockDto;
    }

    /**
     * Get all user block entities associated with a specific block ID.
     * @param blockId - Block ID
     * @returns Promise<UserBlockDto | undefined>
     */
    async getEntityByBlockId(blockId: number): Promise<UserBlockDto | undefined> {
        const res = await databaseService.getByKeyPath<UserBlockDto>(this.storeName, blockId);
        return res.data
    }

    /**
     * Create a new user block with default values.
     * @param block - Block data
     * @param userId - User ID
     * @returns UserBlockDto
     */
    createPureUserBlock(block: BlockDto, userId: number): UserBlockDto {
        return {
            user_id: userId,
            block_id: block.id,
            audio_path: null,
            poll_answer: null,
            long_answer: null,
            completed: 0,
            short_answer: null,
            video_path: null,
        };
    }
}
