import React, {useContext, useEffect, useState} from 'react';
import {Subscription} from "../types/subscription.interface";
import SubscriptionCard from "./subscription-card";
import SkeletonSubscriptionCard from "./skeleton-subscription-card";
import EmptyListSubscriptions from "./empty-list-subscriptions";
import useSubscriptionApi from "../hooks/use-subscription-api";
import {useLocation} from "react-router-dom";
import {OnlineStatusContext} from "../../core/contexts/online-status-context";

const SubscriptionList = () => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [loading, setLoading] = useState(true);
    const {fetchSubscriptions} = useSubscriptionApi();
    const location = useLocation();
    const state = location.state;
    const isOnline = useContext(OnlineStatusContext)

    useEffect(() => {
        const areaId = state && 'areaId' in state ? state['areaId'] : undefined;
        fetchSubscriptions(areaId)
            .then(res => setSubscriptions(res))
            .catch()
            .finally(() => setLoading(false))
    }, []);


    useEffect(() => {
    }, [isOnline]);

    return (
        <>
            {loading || subscriptions.length ? (
                <header>
                    <h2 className="mb-8 text-xl leading-7 font-semibold">Dostępne subskrypcje</h2>
                </header>
            ) : null}
            {!loading
                ? (
                    <>
                        {isOnline && subscriptions.length
                            ? (
                                <section
                                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  lg:mx-20 md:my-10 gap-6 p-[1px] pb-5">
                                    {subscriptions.map((subscription) => (
                                        <SubscriptionCard key={subscription.id} subscription={subscription}/>
                                    ))}
                                </section>
                            )
                            : <EmptyListSubscriptions/>
                        }
                    </>
                )
                : (
                    <div
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:mx-20 md:my-10 gap-6 p-[1px] pb-5 animate-pulse">
                        {Array.from({length: 3}).map((i, index) => <SkeletonSubscriptionCard key={index}/>)}
                    </div>
                )
            }

        </>
    );
};

export default SubscriptionList;
