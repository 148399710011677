import React, { createContext, ReactNode, useContext, useState } from 'react';

// Typy dla kontekstu
interface SyncLoadingContextType {
    isLoading: boolean; // Czy obecnie trwa ładowanie
    startLoading: () => void; // Rozpoczęcie ładowania
    stopLoading: () => void; // Zakończenie ładowania
}

// Domyślna wartość kontekstu
const SyncLoadingContext = createContext<SyncLoadingContextType | undefined>(undefined);

// Provider dla kontekstu
export const SyncLoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const startLoading = () => {
        setIsLoading(true);
    };

    const stopLoading = () => {
        setIsLoading(false);
    };

    return (
        <SyncLoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
            {children}
        </SyncLoadingContext.Provider>
    );
};

// Hook do korzystania z kontekstu w komponentach
export const useSyncLoading = (): SyncLoadingContextType => {
    const context = useContext(SyncLoadingContext);
    if (!context) {
        throw new Error("useSyncLoading must be used within a SyncLoadingProvider");
    }
    return context;
};
