import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {BlockContentDto} from "../models/dto/block-content-dto.interface";
import {UserAreaDto} from "../models/dto/user-area-dto.interface";
import databaseService from "../indexedDB.service";
import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";

export class UserChapterRepository extends BaseRepository<UserChapterDto> {
    constructor() {
        super(IndexedDBStores.USER_CHAPTER);
    }

    async hasChapterIdForUserId(chapterId: number): Promise<boolean> {
        const userChapterDto: UserChapterDto | undefined = await this.getEntityByChapterId(chapterId);
        return !!userChapterDto;
    }

    private async getEntityByChapterId(chapterId: number): Promise<UserChapterDto | undefined> {
        const res = await databaseService.getByKeyPath<UserChapterDto>(this.storeName, chapterId);
        return res?.data
    }
}
