import {IDBPDatabase} from "idb";
import {notes_02} from "./02_notes";
import {init_01} from "./01_init";
import {add_entities_from_backend_03} from "./03-add-entities-from-backend";

export const migrations = {
    1: (db: IDBPDatabase, transaction: IDBTransaction) => init_01(db, transaction),
    2: (db: IDBPDatabase, transaction: IDBTransaction) => notes_02(db, transaction),
    3: (db: IDBPDatabase, transaction: IDBTransaction) => add_entities_from_backend_03(db, transaction),
};
