import {SubareaRepository} from "../repositories/subarea.repository";
import {ElementListSubarea} from "../../../dashboard/types/sub-area.interface";
import {SubareaDto} from "../models/dto/subarea-dto.interface";
import {AreaRepository} from "../repositories/area.repository";
import {AreaDto} from "../models/dto/area-dto.interface";
import {AreaService} from "./area.service";
import {UserSubareaService} from "./user-subarea.service";
import {UserSubareaRepository} from "../repositories/user-subarea.repository";

export class SubareaService {
    private subareaRepository: SubareaRepository;
    private areaRepository: AreaRepository;
    private userSubareaService: UserSubareaService;
    private userSubareaRepository: UserSubareaRepository;

    constructor() {
        this.subareaRepository = new SubareaRepository();
        this.areaRepository = new AreaRepository();
        this.userSubareaService = new UserSubareaService();
        this.userSubareaRepository = new UserSubareaRepository();
    }

    async getSubareasForUser(areaName: string): Promise<Array<ElementListSubarea>> {
        const area: AreaDto = await this.areaRepository.getAreaByName(areaName);
        const subareas: SubareaDto[] = await this.subareaRepository.getAllEntities();
        const response: ElementListSubarea[] = []

        for (const subarea of subareas) {
            //TODO algorymty zrobic
            let hasAccess = true;
            let userSubarea = await  this.userSubareaRepository.getEntityById(subarea.id)

            // if (area.course) {
            //     hasAccess = await this.areaRepository.existsByUserIdAndCourseId(userId, area.id);
            // }
            //
            // if (area.subscription) {
            //     hasAccess = await this.checkSubscriptionForArea(userId, area.id);
            // }

            if (area.id === subarea.area_id) {
                response.push({
                    id: subarea.id,
                    name: subarea.name,
                    ordinalNumber: subarea.ordinal_number!,
                    isCompleted: !!userSubarea?.completed,
                })
            }
        }

        return response;
    }

    public async createUserSubareaForm(subareaId: number): Promise<void> {
        await this.userSubareaService.addEntityIfExists(subareaId);
    }
}
