import {useEffect, useState} from "react";
import {CalendarEvent} from "../types/calendar-event.interface";
import useCalendarApi from "./use-calendar-api";
import CalendarService from "../services/calendar.service";
import {AxiosError} from "axios";
import {useCalendarForm} from "./use-calendar-form";
import {GetCalendarEntity} from "../types/get-calendar-entity.interface";

interface UseCalendarDataOptions {
    includeAdjacentMonths?: boolean;
}

export interface DayData {
    date: Date;
    isCurrentMonth: boolean;
}

export interface CalendarData {
    days: (DayData | null)[];
    dayItems: Map<string, CalendarEvent[]>;
    loading: boolean;
    error: AxiosError<unknown, any> | null;
}

const useCalendarData = (currentDate: Date, options?: UseCalendarDataOptions): CalendarData => {
    const [days, setDays] = useState<Array<{ date: Date, isCurrentMonth: boolean } | null>>([]);
    const [dayItems, setDayItems] = useState<Map<string, CalendarEvent[]>>(new Map());
    const {refreshKey} = useCalendarForm();

    const {loading, error, getCalendarData} = useCalendarApi();
    const includeAdjacentMonths = options?.includeAdjacentMonths ?? true;

    useEffect(() => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();

        const start = new Date(year, month, 1);
        const end = new Date(year, month + 1, 0);

        const daysArray = generateCalendarDays(start, end, includeAdjacentMonths);
        setDays(daysArray);

        fetchCalendarData(start, end, daysArray);
    }, [currentDate, includeAdjacentMonths,refreshKey]);

    const generateCalendarDays = (start: Date, end: Date, includeAdjacentMonths: boolean) => {
        let firstDayOfWeek = start.getDay();
        firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

        const daysInMonth = end.getDate();
        const year = start.getFullYear();
        const month = start.getMonth();

        const daysArray: Array<{ date: Date, isCurrentMonth: boolean } | null> = [];

        if (includeAdjacentMonths) {
            daysArray.push(...addPreviousMonthDays(year, month, firstDayOfWeek));
        } else {
            for (let i = 0; i < firstDayOfWeek; i++) {
                daysArray.push(null);
            }
        }

        daysArray.push(...addCurrentMonthDays(year, month, daysInMonth));

        if (includeAdjacentMonths) {
            daysArray.push(...addNextMonthDays(year, month, daysArray.length));
        }

        return daysArray;
    };

    const addPreviousMonthDays = (year: number, month: number, firstDayOfWeek: number): Array<{ date: Date, isCurrentMonth: boolean }> => {
        const daysArray: Array<{ date: Date, isCurrentMonth: boolean }> = [];
        for (let i = firstDayOfWeek - 1; i >= 0; i--) {
            const prevMonthDay = new Date(year, month, 0 - i);
            daysArray.push({date: prevMonthDay, isCurrentMonth: false});
        }
        return daysArray;
    };

    const addCurrentMonthDays = (year: number, month: number, daysInMonth: number): Array<{ date: Date, isCurrentMonth: boolean }> => {
        const daysArray: Array<{ date: Date, isCurrentMonth: boolean }> = [];
        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push({date: new Date(year, month, i), isCurrentMonth: true});
        }
        return daysArray;
    };

    const addNextMonthDays = (year: number, month: number, totalDays: number): Array<{ date: Date, isCurrentMonth: boolean }> => {
        const daysArray: Array<{ date: Date, isCurrentMonth: boolean }> = [];
        const remainingDays = (7 - (totalDays % 7)) % 7;
        for (let i = 1; i <= remainingDays; i++) {
            const nextMonthDay = new Date(year, month + 1, i);
            daysArray.push({date: nextMonthDay, isCurrentMonth: false});
        }
        return daysArray;
    };

    const fetchCalendarData = (start: Date, end: Date, daysArray: Array<{ date: Date, isCurrentMonth: boolean } | null>) => {
        getCalendarData(start, end)
            .then((fetchedData) => {
                const processedItems = processEventsAndTasks(fetchedData, daysArray);
                setDayItems(processedItems);
            });
    };

    const processEventsAndTasks = (data: GetCalendarEntity[], daysArray: Array<{ date: Date, isCurrentMonth: boolean } | null>) => {
        const dayItemsMap = new Map<string, CalendarEvent[]>();

        daysArray.forEach(day => {
            if (day) {
                const dayKey = day.date.toDateString();
                dayItemsMap.set(dayKey, []);
            }
        });

        data.forEach(item => {
            const taskStartDate = new Date(item.startDate).setHours(0, 0, 0, 0);
            const taskEndDate = new Date(item.endDate).setHours(23, 59, 59, 999);
            const totalDays = Math.ceil((taskEndDate - taskStartDate) / (1000 * 60 * 60 * 24));
            const isEvent = item.calendarTypeName === "Wydarzenie";

            for (let d = new Date(item.startDate); d.getTime() <= new Date(item.endDate).getTime(); d.setDate(d.getDate() + 1)) {
                const currentDayDate = d.setHours(0, 0, 0, 0);
                const currentDayIndex = Math.ceil((currentDayDate - taskStartDate) / (1000 * 60 * 60 * 24)) + 1;

                let displayTime = '';

                if (isEvent){
                    if (currentDayIndex === 1) {
                        if (totalDays === 1) displayTime = `Cały dzień`;
                        else displayTime = `Rozpoczęcie: ${CalendarService.formatDate(new Date(item.startDate), 'HH:mm')}`;
                    } else if (currentDayIndex === totalDays) {
                        displayTime = `Koniec: ${CalendarService.formatDate(new Date(item.endDate), 'HH:mm')}`;
                    }else{
                        displayTime = `Wydarzenie`;
                    }
                }


                const displayTitle = totalDays > 1
                    ? `(${currentDayIndex} z ${totalDays}) ${item.title}`
                    : item.title;

                const newItem = {
                    ...item,
                    displayTitle,
                    displayTime,
                    isFullDay: item.startDate.includes('T00:00:00') && item.endDate.includes('T00:00:00') && totalDays <= 1
                };

                const dayKey: string = new Date(d).toDateString();
                if (dayItemsMap.has(dayKey)) {
                    dayItemsMap.get(dayKey)?.push(newItem as unknown as CalendarEvent);
                }
            }
        });

        return dayItemsMap;
    };


    return {days, dayItems, loading, error};
};

export default useCalendarData;
