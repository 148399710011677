import {UserAreaDto} from "../models/dto/user-area-dto.interface";
import {UserSubareaDto} from "../models/dto/user-subarea-dto.interface";
import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";
import {UserBlockDto} from "../models/dto/user-block-dto.interface";
import {UserBlockContentDto} from "../models/dto/user-block-content-dto.interface";
import {UserAreaPutRequest} from "../models/interfaces/user-area-put-request.interface";
import {UserSubareaPutRequest} from "../models/interfaces/user-subarea-put-request.interface";
import {UserChapterPutRequest} from "../models/interfaces/user-chapter-put-request.interface";
import {UserBlockPutRequest} from "../models/interfaces/user-block-put-request.interface";
import {UserBlockContentPutRequest} from "../models/interfaces/user-block-content-put-request.interface";
import {CalendarDto} from "../models/dto/calendar-dto.interface";
import {CalendarPutRequest} from "../models/interfaces/calendar-put-request.interface";
import {UserDto} from "../models/dto/user-dto.interface";
import {UserPutRequest} from "../models/interfaces/sync-user-put-request.interface";
import {FileDto} from "../models/dto/file-dto.interface";

export class SyncPutMapper {
    /**
     * Maps an array of UserAreaDto to an array of UserAreaPutRequest.
     */
    public static mapUserAreaDtosToPutRequests(userAreas: UserAreaDto[]): UserAreaPutRequest[] {
        return userAreas.map(userArea => ({
            areaId: userArea.area_id,
            completedPercent: userArea.completed_parent,
        }));
    }

    /**
     * Maps an array of UserSubareaDto to an array of UserSubareaPutRequest.
     */
    public static mapUserSubareaDtosToPutRequests(userSubareas: UserSubareaDto[]): UserSubareaPutRequest[] {
        return userSubareas.map(userSubarea => ({
            subareaId: userSubarea.subarea_id,
            completed: !!userSubarea.completed, // Convert to boolean
        }));
    }

    /**
     * Maps an array of UserChapterDto to an array of UserChapterPutRequest.
     */
    public static mapUserChapterDtosToPutRequests(userChapters: UserChapterDto[]): UserChapterPutRequest[] {
        return userChapters.map(userChapter => ({
            chapterId: userChapter.chapter_id,
            completed: !!userChapter.completed,
            manualCompleted: !!userChapter.manual_completed, // Convert to boolean
        }));
    }

    /**
     * Maps an array of UserBlockDto to an array of UserBlockPutRequest.
     */
    public static mapUserBlockDtosToPutRequests(userBlocks: UserBlockDto[]): UserBlockPutRequest[] {
        return userBlocks.map(userBlock => ({
            blockId: userBlock.block_id,
            shortAnswer: userBlock.short_answer || null,
            longAnswer: userBlock.long_answer || null,
            audioPath: userBlock.audio_path || null,
            videoPath: userBlock.video_path || null,
            pollAnswer: userBlock.poll_answer || null,
            completed: !!userBlock.completed,
        }));
    }

    /**
     * Maps an array of UserBlockContentDto to an array of UserBlockContentPutRequest.
     */
    public static mapUserBlockContentDtosToPutRequests(userBlockContents: UserBlockContentDto[]): UserBlockContentPutRequest[] {
        return userBlockContents.map(userBlockContent => ({
            blockContentId: userBlockContent.block_content_id,
            selected: !!userBlockContent.selected,
            sentence: userBlockContent.sentence || null,
            pyramidOrdinalNumber: userBlockContent.pyramid_ordinal_number || null,
        }));
    }

    /**
     * Maps an array of CalendarDto to an array of CalendarRecordUpdateRequest.
     */
    public static mapCalendarDtosToUpdateRequests(calendars: CalendarDto[]): CalendarPutRequest[] {
        return calendars.map(calendar => ({
            id: calendar.id,
            calendarTypeId: calendar.calendar_type_id,
            title: calendar.title,
            startDate: calendar.start_date,
            endDate: calendar.end_date,
            completed: !!calendar.completed,
            completionDate: calendar.completion_date || null,
            reminders: calendar.reminders || [],
            deleted: calendar?.deleted || null,
        }));
    }

    /**
     * Maps an array of UserDto to an array of UserRequest objects.
     */
    public static mapUserDtoToUpdateRequest(user: UserDto, fileDto?: FileDto): UserPutRequest {
        console.log(user,fileDto)
        return {
            age: user?.age,
            gender: user?.gender,
            profession: user?.profession,
            profilePicture: fileDto ? {
                name: fileDto.name,
                contentBase64: fileDto.base64,
            } : null,
            firstname: user?.firstname,
            lastname: user?.lastname,
            invoiceNip: user?.invoice_nip,
            invoiceApartmentNumber: user?.invoice_apartment_number,
            invoiceBuildingNumber: user?.invoice_building_number,
            invoiceCity: user?.invoice_city,
            invoiceCompany: user?.invoice_company,
            invoiceCountry: user?.invoice_country,
            invoicePostalCode: user?.invoice_postal_code,
            invoiceStreet: user?.invoice_street,
        }
    }

}
