import {ConfigController} from "../models/config-controller.interface";
import NotificationService from "../../../common/services/notification.service";
import {ElementListSubarea} from "../../../dashboard/types/sub-area.interface";
import {SubareaService} from "../services/subarea.service";

export class SubareaController {
    private subareaService: SubareaService;

    constructor() {
        this.subareaService = new SubareaService();
    }

    /**
     * Retrieves subareas for a user based on the provided area name.
     *
     * **Required Parameters in Config:**
     * - `areaName` (string): The name of the area to fetch subareas for.
     */
    async getSubareasForUser(config?: ConfigController): Promise<Array<ElementListSubarea>> {
        try {
            const areaName: string | undefined = config?.params['areaName']
            if (!areaName){
                throw new Error("areaName jest wymagane")
            }
            return await this.subareaService.getSubareasForUser(areaName!)
        } catch (error: any) {
            config?.errorMessage && NotificationService.error(config.errorMessage, error);
            throw error
        }
    }
}
