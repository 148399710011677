import {useCallback, useState} from "react";
import SyncService from "../sync.service";
import {useSyncLoading} from "../../contexts/sync-changes-context";
import LocalStorageService from "../../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../types/local-storage-keys.interface";

/**
 * Custom hook for managing synchronization logic.
 * Provides methods to synchronize all data and fetch specific synchronization data for areas.
 */
export const useSync = () => {
    const [isSyncing, setIsSyncing] = useState(false);
    const [syncError, setSyncError] = useState<string | null>(null);
    const {startLoading, stopLoading} = useSyncLoading();
    /**
     * Performs a full synchronization of local and server data.
     */
    const initFirstSyncApp = useCallback(async () => {
        setIsSyncing(true);
        setSyncError(null);

        try {
            startLoading();
            await SyncService.syncAreaModule();
            await SyncService.syncUrgentHelpModule();
            await SyncService.syncUserModule();
            LocalStorageService.save(LocalStorageKeys.INIT_SYNC, true);
        } catch (error: any) {
            console.error("Synchronization failed:", error.message);
            setSyncError(error.message || "An error occurred during synchronization.");
        } finally {
            setIsSyncing(false);
            stopLoading();
        }
    }, []);

    /**
     * Performs a full synchronization of local and server data.
     */
    const synchronizeApp = useCallback(async () => {
        setIsSyncing(true);
        setSyncError(null);

        try {
            await SyncService.synchronizeApp()
        } catch (error: any) {
            console.error("Synchronization failed:", error.message);
            setSyncError(error.message || "An error occurred during synchronization.");
        } finally {
            setIsSyncing(false);
        }
    }, []);

    /**
     * Fetches synchronization data for areas.
     * @returns Promise<EntityAppSync<any>> - Area synchronization data.
     */
    const syncAreaModule = useCallback(async (): Promise<void> => {
        try {
            await SyncService.syncAreaModule();
        } catch (error: any) {
            console.error("Failed to sync area data:", error.message);
            setSyncError(error.message || "Failed to sync area data.");
        }
    }, []);

    const syncCalendarModule = useCallback(async (): Promise<void> => {
        try {
            await SyncService.syncCalendarModule();
        } catch (error: any) {
            console.error("Failed to sync calendar data:", error.message);
            setSyncError(error.message || "Failed to sync calendar data.");
        }
    }, []);

    const syncSupportModule = useCallback(async (): Promise<void> => {
        try {
            await SyncService.syncUrgentHelpModule();
        } catch (error: any) {
            console.error("Failed to sync support data:", error.message);
            setSyncError(error.message || "Failed to sync support data.");
        }
    }, []);

    const syncUserModule = useCallback(async (): Promise<void> => {
        try {
            await SyncService.syncUserModule();
        } catch (error: any) {
            console.error("Failed to sync support data:", error.message);
            setSyncError(error.message || "Failed to sync support data.");
        }
    }, []);

    return {
        isSyncing,
        syncError,
        synchronizeApp,
        syncAreaModule,
        syncCalendarModule,
        initFirstSyncApp,
        syncSupportModule,
        syncUserModule
    };
};
