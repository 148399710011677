import {BlockRepository} from "../repositories/block.repository";
import {ChapterRepository} from "../repositories/chapter.repository";
import {BlockDto} from "../models/dto/block-dto.interface";
import {
    AppBlockContent,
    AppUserBlock, AppUserBlockContent,
    BlockDTO as BlocksResponse
} from "../../../view-builder/types/get-blocks-response.interface";
import {FileService} from "./file.service";
import {IFileBase64} from "../../../view-builder/types/file-base-64.interface";
import {BlockContentRepository} from "../repositories/block-content.repository";
import {ChapterService} from "./chapter.service";
import {UserBlockRepository} from "../repositories/user-block.repository";
import {UserBlockDto} from "../models/dto/user-block-dto.interface";
import {BlockContentDto} from "../models/dto/block-content-dto.interface";
import {UserBlockContentRepository} from "../repositories/user-block-content.repository";
import {UserBlockContentDto} from "../models/dto/user-block-content-dto.interface";
import {AreaService} from "./area.service";
import {
    ReplyPhraseCompletionContentBlockPutRequest
} from "../models/interfaces/ReplyPhraseCompletionContentBlockPutRequest.interface";
import {
    ReplyPhrasePyramidBlockUpdateRequest
} from "../models/interfaces/ReplyPhrasePyramidBlockUpdateRequest.interface";
import AuthService from "../../../auth/services/auth.service";

export class BlockService {
    private areaService: AreaService;
    private blockRepository: BlockRepository;
    private chapterRepository: ChapterRepository;
    private fileService: FileService;
    private blockContentRepository: BlockContentRepository;
    private chapterService: ChapterService;
    private userBlockRepository: UserBlockRepository;
    private userBlockContentRepository: UserBlockContentRepository;


    constructor() {
        this.chapterService = new ChapterService();
        this.blockRepository = new BlockRepository();
        this.chapterRepository = new ChapterRepository();
        this.fileService = new FileService();
        this.blockContentRepository = new BlockContentRepository();
        this.userBlockRepository = new UserBlockRepository();
        this.userBlockContentRepository = new UserBlockContentRepository();
        this.areaService = new AreaService();
    }

    /**
     * Get blocks for a specific chapter
     * @param chapterName - id chapter
     * @returns Promise<BlockDTO[]>
     */
    async getBlocksForChapter(chapterName: string): Promise<BlocksResponse[]> {
        const chapter = await this.chapterRepository.getChapterByName(chapterName);
        if (!chapter) {
            throw new Error(`Chapter with name '${chapterName}' not found.`);
        }

        await this.chapterService.createUserChapterForm(chapter.id);

        const blocks: BlockDto[] = await this.blockRepository.getBlocksByChapterId(chapter.id);
        const response: BlocksResponse[] = [];

        for (const block of blocks) {
            let image: IFileBase64 | null = null;
            let file: IFileBase64 | null = null;
            const blockContentsDto: BlockContentDto[] = await this.blockContentRepository.getContentsBlock(block.id);
            const userAnswerBlock: UserBlockDto | undefined = await this.userBlockRepository.getEntityByBlockId(block.id,);

            const blockContents = await Promise.all(
                blockContentsDto.map(async (blockContent) => {
                    const userBlockContentDto = await this.userBlockContentRepository.getEntityById(blockContent.id);
                    return this.mapToAppBlockContent(blockContent, userBlockContentDto);
                })
            );

            if (block.file_id) {
                try {
                    const fileDTO = await this.fileService.getFileDto(block.file_id);
                    file = {
                        name: fileDTO.name,
                        contentBase64: fileDTO.base64,
                    }
                } catch (error) {
                    console.error('Error loading image for area:', error);
                    throw error
                }
            }

            if (block.image_id) {
                try {
                    const fileDTO = await this.fileService.getFileDto(block.image_id);
                    image = {
                        name: fileDTO.name,
                        contentBase64: fileDTO.base64,
                    }
                } catch (error) {
                    console.error('Error loading image for area:', error);
                    throw error
                }
            }

            const blockDto: BlocksResponse = {
                id: block.id,
                ordinalNumber: block.ordinal_number!,
                chapterName: chapter.name,
                screenName: "",
                blockTypeId: block.block_type_id,
                blockCode: block.block_code,
                blockType: block.block_type,
                blockInteractive: block.block_interactive,
                blockCategory: block.block_category,
                qshortAnswer: block.q_short_answer,
                qlongAnswer: block.q_long_answer,
                qsingleChoice: block.q_single_choice,
                qmultipleChoice: block.q_multiple_choice,
                minRangeForMultipleChoice: block.min_range_for_multiple_choice,
                maxRangeForMultipleChoice: block.max_range_for_multiple_choice,
                qmultipleChoiceSpecificNumber: block.q_multiple_choice_specific_number,
                exactNumberOfAnswers: block.exact_number_of_answers,
                qmultipleChoiceFreedom: block.q_multiple_choice_freedom,
                contentOfSentence: block.content_of_sentence,
                qpyramid: block.q_pyramid,
                qpoll: block.q_poll,
                qanimation: block.q_animation,
                header: block.header,
                paragraph: block.paragraph,
                videoUrl: block.video_url,
                qmoveOnAsAnswer: block.q_move_on_as_answer,
                calendarTypeId: block.calendar_type_id,
                calendarTypeCode: block.calendar_type_code,
                calendarTitle: block.calendar_title,
                hourForStartDate: block.hour_for_start_date,
                daysCountForStartDate: block.days_count_for_start_date,
                daysCountForEndDate: block.days_count_for_end_date,
                hourForEndDate: block.hour_for_end_date,
                userAnswerBlock: this.mapToUserAnswerBlock(userAnswerBlock),
                blockContents: blockContents,
                image: image,
                file: file,
            };

            console.log(blockContents)

            response.push(blockDto);
        }

        return response;
    }

    /**
     * Create user blocks and associated answers for a specific chapter.
     * @param blockId - ID of the block.
     * @param body
     */
    async updateUserBlockAnswers(blockId: number, body: any): Promise<void> {
        const block: BlockDto | undefined = await this.blockRepository.getEntityById(blockId);

        if (block) {
            const blockCode = block.block_code;
            switch (blockCode) {
                case "SHORT_ANSWER":
                    await this.updateShortAnswerBlock(blockId, body["content"]);
                    break;
                case "LONG_ANSWER":
                    await this.updateLongAnswerBlock(blockId, body["content"]);
                    break;
                case "AUDIO_RECORDING":
                    await this.updateAudioRecordingBlock(blockId, body["content"]);
                    break;
                case "VIDEO_RECORDING":
                    await this.updateVideoRecordingBlock(blockId, body["content"]);
                    break;
                case "POLL":
                    await this.updatePollBlock(blockId, body["content"]);
                    break;
                case "SINGLE_CHOICE":
                    await this.updateSingleChoiceBlock(blockId, Number(body["blockContentId"]));
                    break;
                case "MULTIPLE_CHOICE_FIXED_RANGE":
                    await this.updateMultipleChoiceFixedRangeBlock(blockId, body["blockContentIds"]);
                    break;
                case "MULTIPLE_CHOICE_FIXED_COUNT":
                    await this.updateMultipleChoiceFixedCountBlock(blockId, body["blockContentIds"]);
                    break;
                case "MULTIPLE_CHOICE_ANY":
                    await this.updateMultipleChoiceAny(blockId, body["blockContentIds"]);
                    break;
                case "PHRASE_COMPLETION":
                    await this.updatePhraseCompletionBlock(blockId, body["answers"]);
                    break;
                case "PHRASE_PYRAMID":
                    await this.updatePhrasePyramidBlock(blockId, body);
                    break;
                case "NEXT_STEP_AS_ANSWER":
                    await this.updateNextStepAsAnswerBlock(blockId, body["blockContentId"]);
                    break;
                case "NEXT_STEP":
                    await this.updateNextStepBlock(block);
                    break;
                default:
                    console.warn(`BlockService.updateUserBlockAnswers: Unhandled block type: ${blockCode}`);
            }
        }
    }

    async createUserBlocksAnswers(parentName: string, parent: "CHAPTER" | "SCREEN"): Promise<void> {
        const userId = Number(AuthService.authenticatedUser?.id!);
        let blocks: BlockDto[] = [];

        if (parent === "CHAPTER") {
            const chapter = await this.chapterRepository.getChapterByName(parentName);
            if (!chapter) {
                throw new Error(`Chapter with name '${parentName}' not found.`);
            }
            blocks = await this.blockRepository.getBlocksByChapterId(chapter.id);
        } else {
            throw new Error("Invalid parent type");
        }

        for (const block of blocks) {
            const blockCode = block.block_code;

            switch (blockCode) {
                case "SHORT_ANSWER":
                    await this.prepareShortAnswerBlock(block, userId);
                    break;
                case "LONG_ANSWER":
                    await this.prepareLongAnswerBlock(block, userId);
                    break;
                case "SINGLE_CHOICE":
                case "MULTIPLE_CHOICE_FIXED_RANGE":
                case "MULTIPLE_CHOICE_FIXED_COUNT":
                case "MULTIPLE_CHOICE_ANY":
                case "NEXT_STEP_AS_ANSWER":
                    await this.prepareMultipleChoiceBlock(block, userId);
                    break;
                case "PHRASE_COMPLETION":
                    await this.preparePhraseCompletionBlock(block, userId);
                    break;
                case "PHRASE_PYRAMID":
                    await this.preparePhrasePyramidBlock(block, userId);
                    break;
                case "AUDIO_RECORDING":
                    await this.prepareAudioRecordingBlock(block, userId);
                    break;
                case "VIDEO_RECORDING":
                    await this.prepareVideoRecordingBlock(block, userId);
                    break;
                case "POLL":
                    await this.preparePollBlock(block, userId);
                    break;
                case "NEXT_STEP":
                    await this.prepareNextStepBlock(block, userId);
                    break;
            }
        }
    }

    private async prepareShortAnswerBlock(block: BlockDto, userId: number): Promise<void> {
        if (!(await this.userBlockRepository.isExists(block.id))) {
            const userBlock: UserBlockDto = this.userBlockRepository.createPureUserBlock(block, userId);
            await this.userBlockRepository.createEntity(userBlock);
        }
    }

    private async prepareLongAnswerBlock(block: BlockDto, userId: number): Promise<void> {
        if (!(await this.userBlockRepository.isExists(block.id))) {
            const userBlock = this.userBlockRepository.createPureUserBlock(block, userId);
            await this.userBlockRepository.createEntity(userBlock);
        }
    }

    private async prepareMultipleChoiceBlock(block: BlockDto, userId: number): Promise<void> {
        const blockContents = await this.blockContentRepository.getContentsBlock(block.id);
        for (const blockContent of blockContents) {
            if (!(await this.userBlockContentRepository.isExistsByBlockContentId(blockContent.id))) {
                const userBlockContent = this.userBlockContentRepository.createPureUserBlockContent(blockContent, userId);
                await this.userBlockContentRepository.createEntity(userBlockContent);
            }
        }
    }

    private async preparePhraseCompletionBlock(block: BlockDto, userId: number): Promise<void> {
        const blockContents = await this.blockContentRepository.getContentsBlock(block.id);
        for (const blockContent of blockContents) {
            if (!(await this.userBlockContentRepository.isExistsByBlockContentId(blockContent.id))) {
                const userBlockContent = this.userBlockContentRepository.createPureUserBlockContent(blockContent, userId);
                await this.userBlockContentRepository.createEntity(userBlockContent);
            }
        }
    }

    private async preparePhrasePyramidBlock(block: BlockDto, userId: number): Promise<void> {
        const blockContents = await this.blockContentRepository.getContentsBlock(block.id);
        let ordinalNumber = 1;
        for (const blockContent of blockContents) {
            if (!(await this.userBlockContentRepository.isExistsByBlockContentId(blockContent.id))) {
                const userBlockContent = this.userBlockContentRepository.createPureUserBlockContent(blockContent, userId);
                userBlockContent.pyramid_ordinal_number = ordinalNumber++;
                await this.userBlockContentRepository.createEntity(userBlockContent);
            }
        }
    }

    private async prepareAudioRecordingBlock(block: BlockDto, userId: number): Promise<void> {
        if (!(await this.userBlockRepository.isExists(block.id))) {
            const userBlock = this.userBlockRepository.createPureUserBlock(block, userId);
            userBlock.audio_path = null;
            await this.userBlockRepository.createEntity(userBlock);
        }
    }

    private async prepareVideoRecordingBlock(block: BlockDto, userId: number): Promise<void> {
        if (!(await this.userBlockRepository.isExists(block.id))) {
            const userBlock = this.userBlockRepository.createPureUserBlock(block, userId);
            userBlock.video_path = null;
            await this.userBlockRepository.createEntity(userBlock);
        }
    }

    private async preparePollBlock(block: BlockDto, userId: number): Promise<void> {
        if (!(await this.userBlockRepository.isExists(block.id))) {
            const userBlock = this.userBlockRepository.createPureUserBlock(block, userId);
            userBlock.poll_answer = null;
            await this.userBlockRepository.createEntity(userBlock);
        }
    }

    private async prepareNextStepBlock(block: BlockDto, userId: number): Promise<void> {
        if (!(await this.userBlockRepository.isExists(block.id))) {
            const userBlock = this.userBlockRepository.createPureUserBlock(block, userId);
            userBlock.completed = 0;
            await this.userBlockRepository.createEntity(userBlock);
        }
    }

    private async updateShortAnswerBlock(blockId: number, content: string): Promise<void> {
        const userBlockDto = await this.userBlockRepository.getEntityByBlockId(blockId);

        if (userBlockDto && content) {
            const updatedUserBlock: UserBlockDto = {
                ...userBlockDto,
                short_answer: content,
                completed: 1,
            };
            await this.userBlockRepository.updateEntity(updatedUserBlock);

            const blockDto = await this.blockRepository.getEntityById(blockId);
            if (!blockDto) {
                throw new Error(`Block with ID ${blockId} not found`);
            }

            await this.areaService.updateCompletionStatus();
        }
    }

    private async updateLongAnswerBlock(blockId: number, content: string): Promise<void> {
        const userBlockDto = await this.userBlockRepository.getEntityByBlockId(blockId);

        if (userBlockDto && content) {
            const updatedUserBlock: UserBlockDto = {
                ...userBlockDto,
                long_answer: content,
                completed: 1,
            };
            await this.userBlockRepository.updateEntity(updatedUserBlock);

            const blockDto = await this.blockRepository.getEntityById(blockId);
            if (!blockDto) {
                throw new Error(`Block with ID ${blockId} not found`);
            }

            await this.areaService.updateCompletionStatus();
        }
    }

    private async updateAudioRecordingBlock(blockId: number, content: string): Promise<void> {
        const userBlockDto = await this.userBlockRepository.getEntityByBlockId(blockId);

        if (userBlockDto && content) {
            const updatedUserBlock: UserBlockDto = {
                ...userBlockDto,
                audio_path: content,
                completed: 1,
            };
            await this.userBlockRepository.updateEntity(updatedUserBlock);

            const blockDto = await this.blockRepository.getEntityById(blockId);
            if (!blockDto) {
                throw new Error(`Block with ID ${blockId} not found`);
            }

            await this.areaService.updateCompletionStatus();
        }
    }

    private async updateVideoRecordingBlock(blockId: number, content: string): Promise<void> {
        const userBlockDto = await this.userBlockRepository.getEntityByBlockId(blockId);

        if (userBlockDto && content) {
            const updatedUserBlock: UserBlockDto = {
                ...userBlockDto,
                video_path: content,
                completed: 1,
            };
            await this.userBlockRepository.updateEntity(updatedUserBlock);

            const blockDto = await this.blockRepository.getEntityById(blockId);
            if (!blockDto) {
                throw new Error(`Block with ID ${blockId} not found`);
            }

            await this.areaService.updateCompletionStatus();
        }
    }

    private async updatePollBlock(blockId: number, content: number): Promise<void> {
        const userBlockDto = await this.userBlockRepository.getEntityByBlockId(blockId);

        if (userBlockDto && content) {
            const updatedUserBlock: UserBlockDto = {
                ...userBlockDto,
                poll_answer: content,
                completed: 1,
            };
            await this.userBlockRepository.updateEntity(updatedUserBlock);

            const blockDto = await this.blockRepository.getEntityById(blockId);
            if (!blockDto) {
                throw new Error(`Block with ID ${blockId} not found`);
            }

            await this.areaService.updateCompletionStatus();
        }
    }

    private async updateSingleChoiceBlock(blockId: number, blockContentId: number): Promise<void> {
        const userBlockContents: UserBlockContentDto[] =
            await this.userBlockContentRepository.getAllEntitiesBlockContentId(blockContentId);

        if (userBlockContents.length < 1) {
            throw new Error(`No user block content found for blockId: ${blockId}`);
        }

        let anySelected = false;

        for (const userBlockContent of userBlockContents) {
            if (userBlockContent.block_content_id == blockContentId) {
                const updatedUserBlockContent: UserBlockContentDto = {
                    ...userBlockContent,
                    selected: 1,
                };
                anySelected = true;
                await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
            } else {
                const updatedUserBlockContent: UserBlockContentDto = {
                    ...userBlockContent,
                    selected: 0,
                };
                await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
            }
        }

        if (!anySelected) {
            throw new Error(
                `No matching block content found for blockContentId: ${blockContentId}`
            );
        }

        const blockDto = await this.blockRepository.getEntityById(blockId);
        if (!blockDto) {
            throw new Error(`Block with ID ${blockId} not found`);
        }

        await this.areaService.updateCompletionStatus();
    }

    private async updateMultipleChoiceFixedRangeBlock(blockId: number, blockContentIds: number[]): Promise<void> {
        const userBlockContents: UserBlockContentDto[] =
            await this.userBlockContentRepository.getAllEntitiesByBlockId(blockId);

        if (!userBlockContents.length) {
            throw new Error(`No user block content found for blockId: ${blockId}`);
        }

        const validBlockContentIds = new Set(
            userBlockContents.map((content) => content.block_content_id)
        );

        for (const id of blockContentIds) {
            if (!validBlockContentIds.has(id)) {
                throw new Error(
                    `BlockContentId ${id} does not belong to the block with ID ${blockId}`
                );
            }
        }

        const blockDto = await this.blockRepository.getEntityById(blockId);
        if (!blockDto) {
            throw new Error(`Block with ID ${blockId} not found`);
        }

        const minRange = blockDto.min_range_for_multiple_choice || 0;
        const maxRange = blockDto.max_range_for_multiple_choice || Infinity;

        if (blockContentIds.length < minRange || blockContentIds.length > maxRange) {
            throw new Error(
                `The number of selected answers must be between ${minRange} and ${maxRange}`
            );
        }

        for (const userBlockContent of userBlockContents) {
            const isSelected = blockContentIds.includes(userBlockContent.block_content_id);

            const updatedUserBlockContent: UserBlockContentDto = {
                ...userBlockContent,
                selected: isSelected ? 1 : 0,
            };

            await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
        }

        await this.areaService.updateCompletionStatus();
    }

    private async updateMultipleChoiceFixedCountBlock(blockId: number, blockContentIds: number[]): Promise<void> {
        const userBlockContents: UserBlockContentDto[] =
            await this.userBlockContentRepository.getAllEntitiesByBlockId(blockId);

        if (!userBlockContents.length) {
            throw new Error(`No user block content found for blockId: ${blockId}`);
        }

        const validBlockContentIds = new Set(
            userBlockContents.map((content) => content.block_content_id)
        );

        for (const id of blockContentIds) {
            if (!validBlockContentIds.has(id)) {
                throw new Error(
                    `BlockContentId ${id} does not belong to the block with ID ${blockId}`
                );
            }
        }

        const blockDto = await this.blockRepository.getEntityById(blockId);
        if (!blockDto) {
            throw new Error(`Block with ID ${blockId} not found`);
        }

        const exactNumberOfAnswers = blockDto.exact_number_of_answers;
        if (blockContentIds.length !== exactNumberOfAnswers) {
            throw new Error(
                `The number of selected answers must be exactly ${exactNumberOfAnswers}`
            );
        }

        for (const userBlockContent of userBlockContents) {
            const isSelected = blockContentIds.includes(userBlockContent.block_content_id);

            const updatedUserBlockContent: UserBlockContentDto = {
                ...userBlockContent,
                selected: isSelected ? 1 : 0,
            };

            await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
        }

        await this.areaService.updateCompletionStatus();
    }

    private async updateMultipleChoiceAny(blockId: number, blockContentIds: number[]): Promise<void> {
        if (!blockContentIds || blockContentIds.length === 0) {
            throw new Error("At least one answer must be selected.");
        }

        const userBlockContents: UserBlockContentDto[] =
            await this.userBlockContentRepository.getAllEntitiesByBlockId(blockId);

        if (!userBlockContents.length) {
            throw new Error(`No user block content found for blockId: ${blockId}`);
        }

        const validBlockContentIds = new Set(
            userBlockContents.map((content) => content.block_content_id)
        );

        for (const id of blockContentIds) {
            if (!validBlockContentIds.has(id)) {
                throw new Error(
                    `BlockContentId ${id} does not belong to the block with ID ${blockId}`
                );
            }
        }

        const blockDto = await this.blockRepository.getEntityById(blockId);
        if (!blockDto) {
            throw new Error(`Block with ID ${blockId} not found`);
        }

        for (const userBlockContent of userBlockContents) {
            const isSelected = blockContentIds.includes(userBlockContent.block_content_id);

            const updatedUserBlockContent: UserBlockContentDto = {
                ...userBlockContent,
                selected: isSelected ? 1 : 0,
            };

            await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
        }

        await this.areaService.updateCompletionStatus();
    }

    private async updatePhraseCompletionBlock(blockId: number, answers: ReplyPhraseCompletionContentBlockPutRequest[]): Promise<void> {
        const blockDto = await this.blockRepository.getEntityById(blockId);
        if (!blockDto) {
            throw new Error(`Block with ID ${blockId} not found`);
        }

        const userBlockContents: UserBlockContentDto[] =
            await this.userBlockContentRepository.getAllEntitiesByBlockId(blockId);

        for (const userBlockContent of userBlockContents) {
            const answer = answers.find(
                (a) => a.blockContentId === userBlockContent.block_content_id
            );

            if (!answer) {
                throw new Error(
                    `No matching block content found for block content ID: ${userBlockContent.block_content_id}`
                );
            }

            this.validateSentence(answer.sentence);

            const updatedUserBlockContent: UserBlockContentDto = {
                ...userBlockContent,
                sentence: answer.sentence,
            };

            await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
        }

        await this.areaService.updateCompletionStatus();
    }

    private async updatePhrasePyramidBlock(blockId: number, request: ReplyPhrasePyramidBlockUpdateRequest): Promise<void> {
        const blockContents = await this.userBlockContentRepository.getAllEntitiesByBlockId(blockId);

        console.log(request)
        if (!blockContents.length) {
            throw new Error(`No block contents found for blockId: ${blockId}`);
        }

        const maxOrdinalNumber = blockContents.reduce(
            (max, content) => (content.pyramid_ordinal_number ? Math.max(max, content.pyramid_ordinal_number) : max),
            0
        );

        if (request.newOrdinalNumber < 1 || request.newOrdinalNumber > maxOrdinalNumber + 1) {
            throw new Error(
                `Ordinal number must be between 1 and ${maxOrdinalNumber + 1}`
            );
        }

        const targetContent = blockContents.find(
            (content) => content.block_content_id === request.blockContentId
        );

        if (!targetContent) {
            throw new Error(
                `No matching block content found for blockContentId: ${request.blockContentId}`
            );
        }

        const currentOrdinalNumber = targetContent.pyramid_ordinal_number ?? 0;

        if (request.newOrdinalNumber === currentOrdinalNumber) {
            return;
        }

        for (const content of blockContents) {
            let updatedContent: UserBlockContentDto | null = null;
            if (
                request.newOrdinalNumber > currentOrdinalNumber &&
                content.pyramid_ordinal_number !== null &&
                content.pyramid_ordinal_number > currentOrdinalNumber &&
                content.pyramid_ordinal_number <= request.newOrdinalNumber
            ) {
                updatedContent = {
                    ...content,
                    pyramid_ordinal_number: content.pyramid_ordinal_number - 1
                }
            } else if (
                request.newOrdinalNumber < currentOrdinalNumber &&
                content.pyramid_ordinal_number !== null &&
                content.pyramid_ordinal_number >= request.newOrdinalNumber &&
                content.pyramid_ordinal_number < currentOrdinalNumber
            ) {
                updatedContent = {
                    ...content,
                    pyramid_ordinal_number: content.pyramid_ordinal_number + 1
                }
            }

            if (updatedContent) {
                await this.userBlockContentRepository.updateEntity(updatedContent);
            }
        }

        targetContent.pyramid_ordinal_number = request.newOrdinalNumber;
        await this.userBlockContentRepository.updateEntity(targetContent);

        await this.areaService.updateCompletionStatus();
    }

    private async updateNextStepAsAnswerBlock(blockId: number, blockContentId: number): Promise<void> {
        const userBlockContents: UserBlockContentDto[] =
            await this.userBlockContentRepository.getAllEntitiesByBlockId(blockId);

        if (!userBlockContents.length) {
            throw new Error(`No user block content found for blockId: ${blockId}`);
        }

        let anySelected = false;

        for (const userBlockContent of userBlockContents) {
            if (userBlockContent.block_content_id == blockContentId) {
                const updatedUserBlockContent: UserBlockContentDto = {
                    ...userBlockContent,
                    selected: 1,

                };
                console.log(updatedUserBlockContent)
                anySelected = true;
                await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
            } else {
                const updatedUserBlockContent: UserBlockContentDto = {
                    ...userBlockContent,
                    selected: 0,
                };
                await this.userBlockContentRepository.updateEntity(updatedUserBlockContent);
            }
        }

        if (!anySelected) {
            throw new Error(
                `No matching block content found for blockContentId: ${blockContentId}`
            );
        }

        const blockDto = await this.blockRepository.getEntityById(blockId);
        if (!blockDto) {
            throw new Error(`Block with ID ${blockId} not found`);
        }

        await this.areaService.updateCompletionStatus();
    }

    private async updateNextStepBlock(block: BlockDto): Promise<void> {
        let userBlock: UserBlockDto | undefined = await this.userBlockRepository.getEntityByBlockId(block.id);

        if (userBlock) {
            const updatedUserBlock: UserBlockDto = {
                ...userBlock,
                completed: 1,
            };

            await this.userBlockRepository.updateEntity(updatedUserBlock);
        }

        await this.areaService.updateCompletionStatus();
    }

    private validateSentence(sentence: string): void {
        if (!sentence || sentence.trim().length === 0) {
            throw new Error("Sentence cannot be empty");
        }
    }

    /**
     * Maps a UserBlockDto to a UserAnswerBlock.
     * @param userBlockDto - UserBlockDto object
     * @returns UserAnswerBlock or undefined if input is null or undefined
     */
    private mapToUserAnswerBlock(userBlockDto: UserBlockDto | undefined): AppUserBlock | undefined {
        if (!userBlockDto) {
            return undefined;
        }

        return {
            audioPath: userBlockDto.audio_path!,
            completed: userBlockDto.completed == 1,
            shortAnswer: userBlockDto.short_answer!,
            longAnswer: userBlockDto.long_answer!,
            pollAnswer: userBlockDto.poll_answer!,
            videoPath: userBlockDto.video_path!,
        };
    }

    /**
     * Maps a BlockContentDto to an AppBlockContent.
     * Includes userAnswerBlockContent if provided.
     *
     * @param blockContent - BlockContentDto object
     * @param userBlockContent - UserBlockContentDto object or undefined
     * @returns AppBlockContent
     */
    private mapToAppBlockContent(blockContent: BlockContentDto, userBlockContent?: UserBlockContentDto): AppBlockContent {
        const userAnswerBlockContent: AppUserBlockContent | undefined = userBlockContent
            ? {
                selected: !!userBlockContent.selected,
                sentence: userBlockContent.sentence || null,
                pyramidOrdinalNumber: userBlockContent.pyramid_ordinal_number || null,
            }
            : undefined;

        return {
            id: blockContent.id,
            content: blockContent.content,
            userAnswerBlockContent,
        };
    }
}
