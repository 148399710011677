import React, {useRef, useState} from 'react';
import useElementStates from "../../core/hooks/use-element-states";
import {Chapter as IChapter, ElementListChapter} from "../types/chapter.interface";
import {useNavigate, useParams} from "react-router-dom";
import Completed from "./completed";
import {TypeParent} from "../../view-builder/types/type-parent.interface";
import {useDynamicViewApi} from "../../view-builder/hooks/use-dynamic-view-api";
import {useRetryRequest} from "../../core/hooks/use-retry-request";
import withAnyProvider from "../../common/hoc/withAnyProvider";
import {ScenarioProvider} from "../../view-builder/contexts/scenario-context";
import useAreaApi from "../hooks/use-area-api";
import {classNames} from "../../../utils/class-names";
import LoaderIcon from "../../common/components/loader-icon";
import {ModalProvider} from "../../common/contexts/modal-context";
import ChapterHardResetPrompt from "./chapter-hard-reset-prompt";

interface CardChapterProps {
    chapter: ElementListChapter
    updateChapter: (chapter: ElementListChapter) => void
}

const CardChapter: React.FC<CardChapterProps> = (props) => {
    const refChapter = useRef<HTMLElement>(null)
    const {isPressed, isHovered} = useElementStates({ref: refChapter})
    const navigate = useNavigate();
    const params = useParams();
    const {createPureBlocks} = useDynamicViewApi();
    const retryRequest = useRetryRequest();
    const {changeManualCompletedChapter, updateAreasStatus} = useAreaApi();
    const [loading, setLoading] = useState<boolean>(false);
    const [showResetPrompt, setShowResetPrompt] = useState(false);


    const goToChapter = () => {
        retryRequest(
            () => createPureBlocks(props.chapter.name, TypeParent.CHAPTER).then(),
        )
            .then()
            .catch(() => {
            })
            .finally()
        navigate(`/main/${encodeURIComponent(params['area'] ?? '')}/${encodeURIComponent(params['subarea'] ?? '')}/${encodeURIComponent(props.chapter.name)}`, {
            state: {
                isCompleted: props.chapter.isCompleted,
                chapterId: props.chapter.id
            }
        });
    }

    const skipChapter = async (e: React.MouseEvent) => {
        e.stopPropagation();
        setLoading(true);
        await changeManualCompletedChapter(props.chapter.id, true)
            .then(() => props.updateChapter({
                ...props.chapter,
                manualCompleted: true,
            }))
            .finally(() => setLoading(false))
        await updateAreasStatus();
    }

    const clearChapter = async (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowResetPrompt(true);
    }

    const acceptClearChapter = async () => {
        setLoading(true);
        await changeManualCompletedChapter(props.chapter.id, false)
            .then(() => props.updateChapter({
                ...props.chapter,
                manualCompleted: false,
                isCompleted: false,
            }))
            .finally(() => setLoading(false))
        await updateAreasStatus();
    }

    const handleCancelReset = () => {
        setShowResetPrompt(false);
    }

    return (
        <article
            ref={refChapter}
            onClick={goToChapter}
            className={`relative rounded-2xl flex sm:max-h-80 max-h-[80px] sm:min-w-72
            shadow-lg ring-black ring-opacity-5 ring-1 focus:bg-primary_50  overflow-hidden
            transform transition-transform duration-300 cursor-pointer border-primary_600 box-border
            ${isHovered ? 'bg-primary_50' : 'bg-white'} cursor-pointer
            ${isPressed ? 'border-2 !bg-primary_50' : 'border-[1px]'} cursor-pointer`}
        >
            <header className='flex gap-4 rounded-l-lg overflow-hidden flex-grow m-4'>
                <h6 className='text-gray-900 font-semibold text-md break-words truncate'>
                    {props.chapter.name}
                </h6>
            </header>
            <aside className='relative mt-5 mr-4 flex gap-1'>
                {props.chapter.isCompleted || props.chapter.manualCompleted ? (
                    <div onClick={clearChapter}>
                        <Completed isCompleted={true} className='focus:scale-125 hover:scale-125'/>
                    </div>
                ) : (
                    <div onClick={skipChapter}
                         className={classNames(
                             'block w-5 h-5 rounded-full border-2 border-white bg-gray-200 focus:scale-125 hover:scale-125',
                         )}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2.5}
                            stroke="white"
                            className="w-full h-full">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"/>
                        </svg>
                    </div>
                )}
            </aside>
            {loading && (
                <div
                    className='absolute left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.2)] flex justify-center items-center'>
                    <div>
                        <LoaderIcon className='text-primary_600'/>
                    </div>
                </div>
            )}
            {showResetPrompt && (
                <ModalProvider>
                    <ChapterHardResetPrompt onCancel={handleCancelReset} onAccept={acceptClearChapter}
                                            idChapter={props.chapter.id}/>
                </ModalProvider>
            )}
        </article>
    );
}

export default withAnyProvider(ScenarioProvider, CardChapter);
