import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {AreaDto} from "../models/dto/area-dto.interface";
import {UserSubareaDto} from "../models/dto/user-subarea-dto.interface";
import {UserChapterDto} from "../models/dto/user-chapter-dto.interface";
import {SubareaRepository} from "./subarea.repository";

export class AreaRepository extends BaseRepository<AreaDto> {
    private subareaRepository: SubareaRepository;

    constructor() {
        super(IndexedDBStores.AREA);
        this.subareaRepository = new SubareaRepository();
    }

    /**
     * Get an area by its name
     * @param name - Name of the area
     * @returns Promise<AreaDto>
     * @throws Error if no area is found
     */
    async getAreaByName(name: string): Promise<AreaDto> {
        const areas = await this.getAllEntities();

        const area = areas.find((area) => area.name === name);

        if (!area) {
            throw new Error(`Nie ma aktywnego obszaru o nazwie: ${name}`);
        }

        return area;
    }


    // async existsByUserIdAndCourseId(userId: string, courseId: number): Promise<boolean> {
    //     // Pobierz wszystkie rekordy obszarów z bazy danych
    //     const areas = await this.getAll();
    //
    //     // Filtruj obszary, które odpowiadają danemu kursowi i użytkownikowi
    //     const matchingAreas = areas.filter(area => area.id === courseId && area.course === 1);
    //
    //     // W tym przypadku zakładamy, że dostęp do kursu może być ustalony w zależności od dodatkowych danych
    //     // Jeśli wymagana jest bardziej zaawansowana logika (np. powiązania z subskrypcją użytkownika), należy ją tutaj uwzględnić
    //
    //     return matchingAreas.length > 0;
    // }

    // /**
    //  * Get all user subareas by area ID
    //  * @param areaId - ID of the area
    //  * @returns Promise<UserSubareaDto[]>
    //  */
    // async getAllUserSubareasByAreaId(areaId: number): Promise<UserSubareaDto[]> {
    //     const subareas = await this.subareaRepository.getSubareasByAreaId(areaId);
    //     const userSubareas: UserSubareaDto[] = [];
    //
    //     for (const subarea of subareas) {
    //         const userSubarea = await this.userSubareaRepository.getEntityById(subarea.id);
    //         if (userSubarea) {
    //             userSubareas.push(userSubarea);
    //         }
    //     }
    //
    //     return userSubareas;
    // }

    async getUserChaptersByAreaId(areaId: number): Promise<UserChapterDto[]> {
        const userChapters: UserChapterDto[] = [];
        const subareasForArea = await this.subareaRepository.getSubareasByAreaId(areaId);

        for (const subarea of subareasForArea) {
            const userChaptersForSubarea = await this.subareaRepository.getAllUserChaptersBySubareaId(subarea.id)
            if (userChaptersForSubarea) {
                userChapters.push(...userChaptersForSubarea)
            }
        }
        return userChapters;
    }

}
