import {SubscriptionRepository} from "../repositories/subscription.repository";
import {SubscriptionDto} from "../models/dto/subscription-dto.interface";

export class SubscriptionService {
    private subscriptionRepository: SubscriptionRepository;

    constructor() {
        this.subscriptionRepository = new SubscriptionRepository();
    }

    /**
     * Method to fetch all subscriptions from the repository.
     * @returns Promise<SubscriptionDto[]>
     */
    async getAllSubscriptions(): Promise<SubscriptionDto[]> {
        return await this.subscriptionRepository.getAllEntities();
    }
}
