import {useEffect, useRef} from "react";
import {PromptActionHandle} from "../../common/types/prompt-action-handle.interface";
import PromptAction from "../../common/components/prompt-action";
import {CheckCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import Modal from "../../common/components/modal";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import {useModal} from "../../common/hooks/useModal";
import useAreaApi from "../hooks/use-area-api";
import {useNavigate} from "react-router-dom";

interface ChapterHardResetPromptProps {
    onCancel: () => void;
    onAccept: () => Promise<void>;
    idChapter: number;
}

const ChapterHardResetPrompt = (props: ChapterHardResetPromptProps) => {
    const {onCancel, onAccept} = props;
    const resetPromptRef = useRef<PromptActionHandle>(null);
    const {openModal} = useModal();
    const {updateAreasStatus} = useAreaApi();

    const handleConfirm = () => {
        Promise.all([
            onAccept(),
            updateAreasStatus(),
        ])
            .then(() => {
                openModal(ModalIds.SUCCESS_HARD_CLEAR_CHAPTER);
            })
            .catch(() => {
            });
    };

    const handleReject = () => {
        onCancel();
    };

    useEffect(() => {
        resetPromptRef.current?.open();
        return () => resetPromptRef.current?.close();
    }, []);

    return (
        <div>
            <PromptAction
                ref={resetPromptRef}
                title='Na pewno zresetować rozdział?'
                cancelText='Anuluj'
                confirmText='Usuń'
                variant='error'
                showClose={false}
                customIcon={<TrashIcon/>}
                message='Jeśli zresetujesz rodział utracisz wprowadzone odpowiedzi.'
                onConfirm={handleConfirm}
                onReject={handleReject}
            />
            <Modal id={ModalIds.SUCCESS_HARD_CLEAR_CHAPTER} classModal='!min-h-0 !p-5 !border-gray-300'>
                <div className='flex gap-3 items-center '>
                    <CheckCircleIcon className='w-5 h-5'/>
                    <span className='text-lg leading-6 font-semibold'>Progres rozdziału został zresetowany</span>
                </div>
            </Modal>
        </div>
    );
};

export default ChapterHardResetPrompt;
