import {ConfigController} from "../models/config-controller.interface";
import NotificationService from "../../../common/services/notification.service";
import {AreaService} from "../services/area.service";
import {GetMainAreasResponse} from "../../../dashboard/types/get-main-areas.interface";
import {Course} from "../../../courses/types/course.interface";

export class AreaController {
    private areaService: AreaService;

    constructor() {
        this.areaService = new AreaService();
    }

    async getAreasForUser(config?: ConfigController): Promise<GetMainAreasResponse> {
        try {
            return await this.areaService.getAreasForUser()
        } catch (error: any) {
            config?.errorMessage && NotificationService.error(config.errorMessage, error);
            throw error
        }
    }

    async updateUserCompletionStatus(config?: ConfigController): Promise<void> {
        try {
            await this.areaService.updateCompletionStatus();
        } catch (error: any) {
            config?.errorMessage && NotificationService.error(config.errorMessage, error);
            throw error
        }
    }

    async getCourseWithSubareasAndChapters(config?: ConfigController): Promise<Course> {
        try {
            const courseName: string | undefined = config?.params['courseName'];
            if (!courseName) {
                throw new Error("The parameter 'courseName' is required.");
            }
            return await this.areaService.getCourseWithSubareasAndChaptersByName(courseName);
        } catch (error: any) {
            config?.errorMessage && NotificationService.error(config.errorMessage, error);
            throw error;
        }
    }

}
