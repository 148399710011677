import AppWrapper from "../layout/app-wrapper";
import PageLoader from "../modules/common/components/page-loader";
import {useSyncLoading} from "../modules/core/contexts/sync-changes-context";

const SyncLoading = () => {
    const {isLoading} = useSyncLoading();

    if (!isLoading) return null;

    return (
        <AppWrapper>
            <div
                className='z-[10000] absolute bg-white inset-0 w-svw h-svw flex flex-col items-center justify-center gap-12'>
                <div className='relative'>
                    <PageLoader/>
                </div>
                <span className='ml-2 font-bold'>Synchronizacja danych...</span>
            </div>
        </AppWrapper>
    )
}

export default SyncLoading
