import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {BlockContentDto} from "../models/dto/block-content-dto.interface";
import {UserAreaDto} from "../models/dto/user-area-dto.interface";
import databaseService from "../indexedDB.service";
import {UserSubareaDto} from "../models/dto/user-subarea-dto.interface";

export class UserSubareaRepository extends BaseRepository<UserSubareaDto> {
    constructor() {
        super(IndexedDBStores.USER_SUBAREA);
    }

    async hasSubareaIdForUserId(subareaId: number): Promise<boolean> {
        const userSubareaDto: UserSubareaDto | undefined = await this.getEntityBySubareaId(subareaId);
        return !!userSubareaDto;
    }

    private async getEntityBySubareaId(subareaId: number): Promise<UserSubareaDto | undefined> {
        const res = await databaseService.getByKeyPath<UserSubareaDto>(this.storeName, subareaId);
        return res?.data
    }
}
