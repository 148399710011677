import { UserAreaRepository } from "../repositories/user-area.repository";
import AuthService from "../../../auth/services/auth.service";

export class UserAreaService {
    private userAreaRepository: UserAreaRepository;

    constructor() {
        this.userAreaRepository = new UserAreaRepository();
    }

    /**
     * Adds a new user area entity if it does not already exist.
     *
     * @param areaId - The ID of the area to be added for the authenticated user.
     * @throws Will throw an error if the area cannot be added due to repository errors or missing authentication.
     */
    public async addEntityIfExists(areaId: number): Promise<void> {
        try {
            const isExists: boolean = await this.userAreaRepository.hasAreaIdForUserId(areaId);
            const userId: string = AuthService.authenticatedUser?.id!;

            if (!isExists && areaId && userId) {
                await this.userAreaRepository.createEntity({
                    area_id: areaId,
                    user_id: Number(userId),
                    completed_parent: 0,
                });
            }
        } catch (err: any) {
            throw new Error("UserAreaService: Error occurred while adding the entity. Please try again.");
        }
    }
}
