import {BaseRepository} from "./base-repository";
import {IndexedDBStores} from "../models/enums/indexeddb-stores.enum";
import {BlockContentDto} from "../models/dto/block-content-dto.interface";

export class BlockContentRepository extends BaseRepository<BlockContentDto> {
    constructor() {
        super(IndexedDBStores.BLOCK_CONTENT);
    }

    /**
     * Get all content blocks for a specific block ID
     * @param blockId - ID of the chapter
     * @returns Promise<BlockContentDto[]>
     * @throws Error if no blocks are found for the chapter
     */
    async getContentsBlock(blockId: number): Promise<BlockContentDto[]> {
        const contentBlocks: BlockContentDto[] = await this.getAllEntities();
        return contentBlocks.filter(el => el.block_id === blockId);
    }

    /**
     * Get all BlockContent entities by blockId.
     * @param blockId - ID of the block
     * @returns Promise<BlockContentDto[]>
     */
    async getAllEntitiesByBlockId(blockId: number): Promise<BlockContentDto[]> {
        const allEntities: BlockContentDto[] = await this.getAllEntities();

        return allEntities.filter(
            (blockContent) => blockContent.block_id === blockId
        );
    }
}
