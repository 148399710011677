import {TransactionService} from "../services/transaction.service";
import {TransactionDto} from "../models/dto/transaction-dto.interface";
import {
    CoursesHistoryTransaction,
    CourseTransaction
} from "../../../dashboard/types/courses-history-transaction.interface";
import {SubscriptionTransaction} from "../../../subscriptions/types/subscriptions-history-transaction";

export class TransactionController {
    private transactionService: TransactionService;

    constructor() {
        this.transactionService = new TransactionService();
    }

    /**
     * Method to fetch all subscription transactions.
     * @returns Promise<TransactionDto[]>
     */
    async getSubscriptionTransactions(): Promise<SubscriptionTransaction[]> {
        return await this.transactionService.getAllTransactionSub();
    }

    /**
     * Method to fetch all course transactions.
     * @returns Promise<TransactionDto[]>
     */
    async getCourseTransactions(): Promise<CourseTransaction[]> {
        return await this.transactionService.getAllTransactionCourse();
    }
}
