import {UserService} from "../services/user.service";
import {GetUserData} from "../../types/get-user-data.interface";
import {RequestBodyUserData} from "../../types/request-body-user-data.interface";
import {RequestBodyUploadProfileImage} from "../../types/request-body-upload-profile-image.interface";
import {ConfigController} from "../models/config-controller.interface";
import NotificationService from "../../../common/services/notification.service";

export class UserController {
    private userService: UserService;

    constructor() {
        this.userService = new UserService();
    }

    async uploadProfilePicture(fileUploadRequest: RequestBodyUploadProfileImage,config?: ConfigController): Promise<void> {
        try {
            await this.userService.updateProfilePicture(fileUploadRequest);
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            console.error("Failed to update profile picture:", error.message);
            throw error;
        }
    }

    async updateUserData(userDataPutRequest: RequestBodyUserData,config?: ConfigController): Promise<void> {
        try {
            await this.userService.updateUserData(userDataPutRequest);
            if (config?.successMessage) {
                NotificationService.success(config.successMessage);
            }
        } catch (error: any) {
            if (config?.errorMessage) {
                NotificationService.error(config.errorMessage, error);
            }
            console.error("Failed to update user data:", error.message);
            throw error;
        }
    }

    async getUserData(): Promise<GetUserData> {
        try {
            return await this.userService.getUserData();
        } catch (error: any) {
            console.error("Failed to fetch user data:", error.message);
            throw error;
        }
    }
}
